* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  font-family: "AnebaNeue";
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin-bottom: 0;
  /* font-family: 'Work Sans'; */

  font-family: "AnebaNeue";

  font-style: normal;
}

/* @import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;400;500;600;700;800;900&display=swap"); */

body,
html {
  /* font-family: "Work Sans"; */
  font-family: "AnebaNeue";
}

body {
  margin: 0;
  padding: 0;
  /* font-family: "Work Sans"; */
  scroll-behavior: smooth;
  font-family: "AnebaNeue";

  border: none;
}

input:focus {
  outline: none;
}

.wrap-login-page {
  width: 100%;
  height: auto;
  display: flex;
}

.left-side-login {
  width: 8%;
  height: auto;
  padding: 2%;
  background-image: linear-gradient(180deg, #091323 0%, #160e24 52.35%, #0f1b25 100%);
  /* background-image: linear-gradient(red, yellow);   */
}

.desk-none {
  display: none;
}

.right-side-login {
  height: 1114px;
  padding: 2%;
  position: absolute;
  z-index: 10;
  width: 31%;
  right: 0%;
  background: linear-gradient(270deg, #150e25 31.96%, rgba(21, 14, 37, 0) 97.33%);
}

.center-side-login {
  width: 84%;
  height: 1114px;
}

.bkloby {
  position: absolute;
  bottom: -101px;
}

.center-side-login-img {
  width: 100%;
  height: 1114px;
}

.login-form {
  padding: 47px 0% 47px 0%;
  background: linear-gradient(120.09deg, rgba(75, 83, 96, 0.8) 7.14%, rgb(0 0 0 / 80%) 81.66%);
  box-shadow: 0px 11px 19px -1px rgba(54, 62, 73, 0.24);
  border-radius: 16px;
  /* position: absolute;
    top: 0%;
    left: 37%;
    margin-top: 246px; */
  width: 29%;
}

.login-logo {
  width: 53px;
  margin: auto;
}

.login-logo img {
  width: 100%;
}

.update-login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  max-height: 180vh;
}

.welcome-bk {
  font-family: "AnebaNeue";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 120.6%;
  margin-top: 4%;
  margin-bottom: 4%;
  text-align: center;

  color: #ffffff;
}

.left {
  text-align: left !important;
}

.login-field {
  width: 87%;
  margin: auto;
}

.mt-resend {
  margin-top: 65px !important;
}

.login-form-control {
  background-color: #030303 !important;
  border: 2px solid #484a4e !important;
  box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.16) !important;
  border-radius: 12px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  height: 64px !important;
  font-weight: 500 !important;
  font-family: "AnebaNeue";
  font-style: normal;

  color: #cbd8e7 !important;
}

.login-label label {
  font-weight: 400 !important;
  font-family: "AnebaNeue";
  font-style: normal;
  line-height: 14px !important;
  top: 7% !important;

  color: #ffffff !important;
}

.signin {
  width: 100%;
  background: radial-gradient(100% 100% at 50% 0%, #ffd951 6.77%, #cb4848 99.48%);
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
  height: 64px;
  font-size: 24px;
  line-height: 137.3%;
  text-align: center;
  color: #000000;
  font-size: bold;
  font-weight: 600;
  margin-top: 36px;
  /* font-family: 'AnebaNeue-SemiBold'; */
  font-family: "AnebaNeue";
}

.signin-christmas{
  background: linear-gradient(90deg, #b80000, #8b0000) !important;
  color: #ffffff !important;
}

.signin:hover {
  /* border-radius: 20px; */
  /* border-radius: 10px; */
  /* border: 2px solid transparent;
  background-clip: padding-box;
  border-image: radial-gradient(77.34% 77.34% at 50% 22.66%, #FFD66E 0%, #FF0044 100%) 5;
  background: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: radial-gradient(77.34% 77.34% at 50% 22.66%, #FFD66E 0%, #FF0044 100%); */
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #8a7f94 0%,
    #4c444e 41.67%,
    #241e35 100%
  );
  box-shadow:
    0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 15px 13px 1px rgba(0, 0, 0, 0.32);
  color: white !important;
}

.signin-des {
  font-size: 16px;
  line-height: 19px;
  color: #aaaaaa;
  margin-top: 5%;
  float: left;
  font-family: "AnebaNeue";
}

.mb-0_5 {
  margin-bottom: 0.5rem;
}

.text-yellow {
  text-decoration: underline;
  color: #ffc120 !important;
  margin-left: 1%;
  cursor: pointer;
  font-family: "AnebaNeue";
}
.text-yellow-christmas{
  color: #ff3c3c !important;
}
.login-body {
  min-height: 100vh;
  max-height: 180vh;
  background-color: #150e25;
  background-image: url("./images/loginpage.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.text-white{
  color: #ffffff !important;
}

.wrap-login-body {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 105px; */
}

.mb_1 {
  margin-bottom: 2%;
}

.text-center {
  text-align: center !important;
}

.showeye {
  position: absolute;
  right: 8px;
  top: 20px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.login-bottom {
  display: flex;
  width: 94%;
  margin: auto;

  justify-content: space-between;
  margin-top: 4%;
}

.login-bottom h1 {
  font-family: "AnebaNeue";
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #aaaaaa;
  cursor: pointer;
  font-weight: 500;
}

.forgot-bk {
  font-family: "AnebaNeue";
  font-size: 16px;
  line-height: 156.8%;
  color: #cbd8e7;
  text-align: center;
}

.mt-reset {
  margin-top: 110px !important;
}

.back {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 4%;
}

.back h1 {
  font-family: "AnebaNeue";
  font-size: 16px;
  line-height: 24px;
  color: #aaaaaa;
  margin-top: 1%;
  cursor: pointer;
}

.back img {
  width: 2vw;
  height: 2vw;
  margin-right: 2%;
  cursor: pointer;
}

.container-2 {
  display: block;
  position: relative;
  padding-left: 6%;
  /* margin-bottom: 12px; */
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "AnebaNeue";
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #aaaaaa;
}

/* Hide the browser's default radio button */
.container-2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #aaaaaa;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-2 input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-2 .checkmark:after {
  /* top: 2px;
  left: 2px; */

  width: 8px;
  height: 8px;

  background: #ffc120;
  border-radius: 2px;
}
.container-2 .checkmark-christmas:after {
  background: #ff3c3c;
}


.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: #fff !important;
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.creation-header {
  width: 80%;
  margin: auto;
  padding-top: 2%;
  display: flex;
  justify-content: space-between;
}

.creation-header-img {
  width: 6%;
  cursor: pointer;
}

.creation-header-img img {
  width: 100%;
}

.creation-header-btn {
  width: 16%;
  /* background: linear-gradient(90deg, #0E0527 0.83%, #0E0724 100%);
   */
  background: #030303;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mt2 {
  margin-top: 3%;
}

.mt6 {
  margin-top: 6%;
}

.w27 {
  width: 27% !important;
}

.mr4 {
  margin-right: 4%;
}
.mr-t-30 {
  margin-top: 30px;
}

.des-d-none {
  display: none !important;
}

.game-setting {
  margin-top: 4%;
}

.six-games {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  /* background-color: aliceblue; */
  justify-content: center;
}
.provider_dropdown {
  width: 168px;
  height: 56px;
  margin-right: 15px;
}
.provider_dropdown_btn {
  width: 168px;
  height: 56px;
  border-radius: 8px 8px 0px 0px !important;
  background: #2e363c !important;
  border: #2e363c !important;
  text-align: left !important;
}
.option_list {
  border-radius: 0px 0px 8px 8px !important;
  background: #1a1a1f !important;
}
.drop_item {
  color: #d2dedd !important;
  font-family: "AnebaNeue";
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 118.3% !important;
}
.drop_item:hover {
  background: #2e363c !important;
}
.dropdown-toggle::after {
  margin-left: 6.255em !important;
  position: absolute;
  right: 8px;
  border: none !important;
  content: url("./images/chevron-bottom.png") !important;
}
.new-err {
  font-family: "AnebaNeue";

  color: #ffc120;

  text-align: left;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 118.3%;
}
.new-err-christmas{
  color: #ff3c3c !important;
}
.signin_fb {
  width: 100%;
  background: #1877f2;
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
  height: 64px;
  font-size: 20px;
  line-height: 137.3%;
  text-align: center;
  color: #fff;
  font-size: bold;
  font-weight: 700;
  margin-top: 10px;
  /* font-family: 'AnebaNeue-SemiBold'; */
  font-family: "AnebaNeue";
}
.signin_fb2 {
  width: 100%;
  background: transparent;
  box-shadow: none;
  border-radius: 0;

  font-size: 20px;
  text-align: center;
  color: #fff;
  font-size: bold;
  font-weight: 700;
  margin-top: 10px;
  /* font-family: 'AnebaNeue-SemiBold'; */
  font-family: "AnebaNeue";
}
.signin_fb img {
  width: 7%;
  margin-right: 1%;
  margin-bottom: 1%;
}
.mt-fb {
  margin-top: 25px;
}
.wrap-gift-card {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10%;
}
.img-gift {
  width: 22%;
  height: auto;
  margin-bottom: 20px;
}
.img-gift img {
  width: 100%;
}
/* text-align: left;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 118.3%;
} */
.signin_fb {
  width: 100%;
  background: #1877f2;
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
  height: 64px;
  font-size: 20px;
  line-height: 137.3%;
  text-align: center;
  color: #fff;
  font-size: bold;
  font-weight: 700;
  margin-top: 10px;
  /* font-family: 'AnebaNeue-SemiBold'; */
  font-family: "AnebaNeue";
}
.signin_fb2 {
  width: 100%;
  background: transparent;
  box-shadow: none;
  border-radius: 0;

  font-size: 20px;
  text-align: center;
  color: #fff;
  font-size: bold;
  font-weight: 700;
  margin-top: 10px;
  /* font-family: 'AnebaNeue-SemiBold'; */
  font-family: "AnebaNeue";
}
.signin_fb img {
  width: 7%;
  margin-right: 1%;
  margin-bottom: 1%;
}
.mt-fb {
  margin-top: 25px;
}
.wrap-gift-card {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10%;
}
.img-gift {
  width: 22%;
  height: auto;
  margin-bottom: 20px;
}
.img-gift img {
  width: 100%;
}
/* text-align: left;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 118.3%;
} */

.goldColor {
  color: #ffc120 !important;
}
.Af48 {
  /* font-weight: bold; */
  font-family: "Aneba Neue";
  font-style: normal;
  font-size: 30px;
  line-height: 105.8%;
  color: #ffffff;
}
.Af17 {
  font-family: "Aneba Neue";
  font-style: normal;
  font-size: 20px;
  line-height: 105.8%;
  color: #ffffff;
  font-weight: 500;
}
.xcd-pro {
  max-width: 576px;
  /* margin: 0 auto; */
  font-weight: bold;
  font-family: "Aneba Neue";
  font-style: normal;
  font-size: 30px;
  line-height: 105.8%;
  color: #ffffff;
  /* width: 47%; */
  margin: auto;
  padding-bottom: 2%;
}
.wtText {
  color: red;
}
.reText {
  color: #aaaaaa;
}
.agreeText {
  color: #aaaaaa;
}
.about-faq button {
  background-color: #2e363c !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 120.6% !important;
  color: #ffffff !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.about-faq button:not(.collapsed) {
  color: #fff !important;
  border-bottom-left-radius: 0px !important;
  box-shadow: none;
  border-bottom-right-radius: 0px !important;
}
.accordion-item {
  background-color: transparent !important;
}
.about-faq-body {
  font-weight: 500 !important;
  font-style: normal !important;
  font-family: "Aneba Neue" !important;
  font-size: 16px !important;
  line-height: 119.3% !important;
  color: #ffffff !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-button::after {
  filter: invert(1) brightness(10);
}
.accordion-item {
  margin-bottom: 9px;
  border: 1px solid transparent !important;
  border-radius: 8px !important;
}
.accordion-item:not(:first-of-type) {
  border-top: 1 !important;
}
.homeLi li {
  font-size: 16px !important;
}
.noft-img {
  height: auto !important;
  object-fit: cover;
  border-radius: 8px;
}
.view_notf_btn {
  color: #ffc120;
  text-decoration: underline;
  cursor: pointer !important;
}
.view_notf_btn-christmas {
  color: #ff3c3c !important;
  
}
.social-link-footer-list {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.social-link-logo {
  width: 25px; /* Adjust the size as needed */
  height: 25px; /* Adjust the size as needed */ 
  color: #ffffff !important;
  transition:
    filter 0.3s ease,
    transform 0.3s ease;
}

.social-link-logo:hover {
  /* filter: brightness(1.5) sepia(30%) hue-rotate(45deg) saturate(120%); */
  transform: scale(1.15); /* Optional: Add a slight zoom effect */
}
#facebook:hover{
  color: rgb(0, 136, 255) !important;
}
#instagram:hover{
  color: #C13584 !important;
}#tiktok:hover{
  color: #00f2ea !important;
}
#youtube:hover{
  color: #FF0000 !important;
}
.tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px;
  border-radius: 5px;
  pointer-events: none;
  display: none; 
  z-index: 1000; 
}
.map-container {
  position: relative;
  overflow: auto;
  width: 100%;
  height: 500px;
  border: 1px solid #ddd;
}
.our-location-wrap{
  /* display: flex; */
  
}

.DecodedContent{
  color: #ffffff;
}
.DecodedContent a{
  color: #ffc120;
}
.wheel-des {
  width: 47%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.btn-wheel {
  width: 304px !important;
  height: 58px !important;
  font-size: 21px !important;
  line-height: 140.8%;
  font-weight: 600 !important;
  color: #000000;
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
}
@keyframes bounce2 {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.bounce2 {
  display: inline-block; /* Optional for better animation visuals */
  animation: bounce 1s ease;
}
@keyframes bounceZoom {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0) scale(1);
  }
  40% {
    transform: translateY(-30px) scale(1.2); /* Bounce up and zoom in */
  }
  60% {
    transform: translateY(-15px) scale(0.9); /* Slightly zoom out */
  }
}

.bounce-zoom {
  display: inline-block; /* Optional for better visuals */
  animation: bounceZoom 1s ease;
}
.bouncing-text {
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  animation: bounce 1s infinite;
}

.wheel-icons.bounce {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}
.wheel-icons{
  cursor: pointer;
  /* background: linear-gradient(90deg, #b80000, #8b0000); */
  background: radial-gradient(100% 100% at 50% 0%, #ffd951 6.77%, #cb5f48 99.48%) !important;
  width: 60px;
  height: 60px;
  position: fixed;
  z-index: 100000;
  bottom: 96px;
  right: 20px;
  border-radius: 50%;
  display: flex
;
  align-items: center;
  justify-content: center;
}
.spinWheel-container{
  display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
}
.spinWheel-container-img{
  width: 453px;
  height: 507px;
}
.spinWheel-container-wrap{
  position: absolute;
    transform: scale(0.87);
    margin: auto;
    rotate: -45deg;
    left: 1px;
    top: -3px;
}
.gTLZXx{
  width: 85px !important;
  height: 87px !important;
  position: absolute !important;
  top: 30px !important;
  rotate: 45deg !important;
  right: 26px !important;
}
.spinWheel-wrap-logo{
  cursor: pointer;
    rotate: 45deg;
    width: 199px;
    height: 202px;
    position: absolute;
    top: 268.5px;
    left: 119.5px;
    z-index: 2000;
    transform: translate(-50%, -50%);
}
.wheel-offcanvas{
  width: 1000px !important;
}
.spin-wheel-btn{
  display: none !important;
}
.location-title{
  margin-top: 100px;
}
.wheel-offcan-body{
  display: flex;
  justify-content: center;
  align-items: center;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;
}
.wheel-offcan-body::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.no-hover:hover{
  color:#cbd8e7 !important
}



.leaderboard-container {
  max-width: 100%;
  
  padding-bottom: 50px;
  padding-top: 10px;

  background: linear-gradient(0deg, rgb(27 2 52) 15%, #410028 36%);
  background: linear-gradient(0deg, #570f0b  15%, #410028 36%);
  color: #fff;
  border-radius: 16px;
  /* border:4px solid #a76f22; */
  
}


.leaderboard-header {
    text-align: center;
    margin-bottom: 5px;
}
.leaderboard-header h1 {
    font-size: 1.8rem;
    margin: 0;
    color:#e1ae4e;
    font-weight: 500;
}
.leaderboard-header p {
    margin: 0;
    color:#e1ae4e;
}
.leaderboard-table {
  border: none;
  border-radius: 4rem;
  padding: 0px 0px 0px;
  border-radius: 17px;
  border-collapse: inherit;
  overflow: hidden;
  margin-bottom: 0px !important;
}
.leaderboard-table td h6 {
  margin: 0;
}
.leaderboard-table th {
  font-size: 1rem;
  color: #e1ae4e !important;
  border: none;
  padding: 1rem 0rem !important;
  background-color: transparent !important;
  
}
.leaderboard-table thead {
/* background: linear-gradient(to left, #6f0c43 0%, #a91663 50%, #6f0c43 100%); */
background: linear-gradient(to left, #860808 0%, #da3333 50%, #860808 100%);
}

.leaderboard-table tr {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px !important;
  width: 99%;
  margin: auto;
}
.leaderboard-table td {
    font-size: 0.9rem;
    color: #fff;
    width: 100%;
   display: flex;
  align-items: center;
  border:none;
}
.leaderboard-table td i {
  padding: 0px 15px 0px 0px;
  color: #e1ae4e;
  font-size: 18px;
}

.leaderboard-table td .silver{
  padding: 0px 15px 0px 0px;
  color: #C0C0C0;
  font-size: 18px;
}
.leaderboard-table tbody tr:nth-child(odd) {
  background: linear-gradient(0deg, #6c0755, #42002c, #6c0755);
  background: linear-gradient(0deg, #b30000, #860808, #6e1818);
  border: 2px solid #860808;
  border-radius: 5px;
  --bs-table-bg: none !important;

}
.leaderboard-table tbody tr:nth-child(even) {
  background: linear-gradient(0deg, #6c0755, #42002c, #6c0755);
  background: linear-gradient(0deg, #b30000, #860808, #6e1818);
  border: 2px solid #860808;
  border-radius:5px !important;
  --bs-table-bg: none !important;
}
.test-gradeient{
  font-size: 44px;
  font-weight: bold;
-webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  /* background: linear-gradient(0deg, rgba(222,158,44,1) 0%, rgba(255, 215, 0, 1) 60%); */
  background: linear-gradient(0deg, rgba(222, 158, 44, 1) 49%, rgba(255, 215, 0, 1) 69%);


}
.font-size-28{
  font-size: 25px !important;
  width: 90%;
  margin: auto;
  text-align: center;
  color: rgba(255, 215, 0, 1);
  font-weight: bold;

  
}
.font-size-20{
  font-size: 20px !important;

}
.btn-gradient-1 {
  border-width: 4px;
  border-style: solid;
  border-image: linear-gradient(to right, darkblue, darkorchid) 1;
}

/* method 2 -> use background-clip to support border-radius */
.btn-gradient-2 {
  background: linear-gradient(white, white) padding-box,
              linear-gradient(to right, darkblue, darkorchid) border-box;
  border-radius: 50em;
  border: 4px solid transparent;
}

/* demo stuff */
.parent {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 3rem;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.btn-gradient-1,
.btn-gradient-2 {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  color: darkblue;
  padding: 0.5rem 1rem;
  cursor: pointer;
}
.leader-gradient{
  max-width: 1100px;
  margin: 50px auto;
  background: linear-gradient(white, white) padding-box,
              linear-gradient(to right, rgba(222, 158, 44, 1), rgba(255, 215, 0, 1)) border-box;
              /* background: linear-gradient(white, white) padding-box,
              linear-gradient(0deg, rgba(186,186,39,1) 10%, rgba(127,95,31,1) 50%, rgba(229,150,8,1) 77%) border-box; */
  border-radius: 20px;
  border: 6px solid transparent;
}
.leader-gradient-2{
 width: 99%;
 margin: auto;
  /* margin: 50px auto; */
  background: linear-gradient(#23121e, #23121e  ) padding-box,
              linear-gradient(to right, rgba(255, 215, 0, 1), rgba(222, 158, 44, 1)) border-box;
              /* background: linear-gradient(white, white) padding-box,
              linear-gradient(0deg, rgba(186,186,39,1) 10%, rgba(127,95,31,1) 50%, rgba(229,150,8,1) 77%) border-box; */
  border-radius: 20px;
  border: 6px solid transparent;
}

.star-num h1{
  position: absolute;
  top: 8px;
  left: 14px;
  font-size: 20px;
  font-weight: 600;
}
.star-num{
  position: relative;
  width: 50px;

}
.user-numbering{
  display: flex;
      width: 100%;
      align-items: center;
}
.star-img{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.star-detail{
  margin-left: 30px;
}
.star-num p {
  margin-bottom: 0%;
  margin-left: 13px;
  font-size: 20px;
  font-weight: 600;
}
.scrollable-tbody {

  display: block;
  max-height: 324px; /* Adjust based on the row height to show 5 rows */
  overflow-y: auto;
  scrollbar-width: none; /* For Firefox */
}
.scrollable-tbody::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}
.should-hide-des{
  display: none !important;
}
.user-profile-a {
  font-weight: 500;
  font-size: 16px;
  line-height: 161.3%;
  color: #cbd8e7;
  text-decoration: none;
  display: flex
;
  align-items: center;
  cursor: pointer;
}
.user-profile-a:hover {
  color: #ffc120;
}

.Referral-container {
  margin-top: 75px;
}

.podium {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  margin-bottom: 20px;
}

.podium-item {
  background-color: #fff;
  background: radial-gradient(70.31% 70.31% at 50% 22.66%, #999 0%, #474747 41.67%, #21221d 100%) !important;
  color: #000;
  border-radius: 10px;
  width: 206px;
  padding: 10px;
  text-align: center;
  position: relative;
}
.podium-item-empty {

  border-radius: 10px;
  width: 206px;

}

.first {
  background-color: #42494f ;
  transform: translateY(-20px);
  border: 2px solid #ffc120 !important;
}

.second {
  background-color: #42494f ;
  transform: translateY(-10px);
}

.third {
  background-color: #42494f ; /* Bronze */
}

.icon-referral {
  width: 96px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 25px;
}
.icon-referral img {
  width: 100%;
border-radius: 50%;
height: 96px;
object-fit: cover;
}

.label-referral {
  font-size: 17px;
  font-weight: 500;
  color: #d2dedd;
}

.score {
  font-size: 30px;
  font-weight: bold;
  color: #ffc120;
}

.sun {
  font-size: 31px;
  position: absolute;
  top: -20px;
  right: -18px;
}

.main-wrapper {
  display: flex;
  align-items: end;
  justify-content: center;
 
}

.main-content {
  /* background: linear-gradient(to right, #ffff66, #ffd700); */
  background: radial-gradient(100% 100% at 50% 0%, #ffd951 6.77%, #cb5f48 99.48%) !important;
  width: 222px;
  height: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -1px;
  border-top: 30px solid orange;
  color: #570f0b !important;
  font-size: 16px;
  clip-path: polygon(30px 0, 100% 0, 100% 100%, 0 100%, 0 30px);
}

.content {
  /* background: linear-gradient(to right, #ffff66, #ffd700); */
  background: radial-gradient(100% 100% at 50% 0%, #ffd951 6.77%, #cb5f48 99.48%) !important;
  width: 222px;
  height: 100px;
  display: flex;
  align-items: center;
  color: #570f0b !important;
  font-size: 16px;
  justify-content: center;
  border-top: 30px solid orange;
  /* clip-path: polygon(9% 0, 90% 0, 100% 7%, 100% 100%, 0 100%, 0 7%); */
  clip-path: polygon(9% 0, 90% 0, 100% 20%, 100% 100%, 0 100%, 0 14%);
}

.content-main {
  /* background: linear-gradient(to right, #ffff66, #ffd700); */
  background: radial-gradient(100% 100% at 50% 0%, #ffd951 6.77%, #cb5f48 99.48%) !important;
  width: 217px;
  height: 80px;
  color: #570f0b !important;
  margin-left: -1px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 30px solid orange;
  /* clip-path: polygon(90% 0, 100% 8%, 100% 100%, 0 100%, 0 0); */
  clip-path: polygon(90% 0, 100% 34%, 100% 100%, 0 100%, 0 0);
}
.title-refeeral-place{
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0 !important;

}
#box {
  cursor: pointer;
  display: flex
  ;
      align-items: center;
      justify-content: center;
      width: 68px;
      height: 21px;
      color: white;
      /* font-family: 'Raleway'; */
      /* font-size: 2.5rem; */
      margin-right: 26px;
}
.gradient-border {
  --borderWidth: 10px;
  background: #1D1F20;
  position: relative;
  border-radius: var(--borderWidth);
}
.gradient-border:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #ffd951, #f37055, #cb5f48, #ffd951, #f37055, #cb5f48);
  /* background: linear-gradient(60deg, #ffd951 , #f37055, #cb5f48 , #474747 , #999 , #21221d, #ffd951 #cb5f48); */

  /* border-radius: calc(2 * var(--borderWidth));
   */
   border-radius: 8px;
  /* z-index: -1; */
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}


@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.giro-table2 tbody tr td {
  /* max-width: 13.6%; */
  width: 10% !important;
  /* padding: 8.7px 16px !important; */
}
.user-detail-table{
  width: auto ; 
  display: flex
;
  align-items: center;
}
.user-detail-table img{
  width: 40px ;
  border-radius: 50%;
  margin-right: 4px;
}
.coin-detail-table{
  width: auto ; 
  display: flex
;
  align-items: center;
}
.coin-detail-table img{
  width: 30px ;
  border-radius: 50%;
  margin-right: 4px;
}
.action-table-referral{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.action-table-referral-btn{
  cursor: pointer;
color: #d2dedd;
font-weight: 600;


}
.action-table-referral-btn:hover{
color: #ffd951;
}
.socket-count{
  position: relative;
  cursor: pointer;


}
.socket-count p{
  position: absolute;
  top: 0;
  color: #fff;
  font-weight: 500;
  font-size: 19px;
  top: 50%;
  left: 33%;
  transform: translate(-50%, -50%);
}
.text-container-popup-agree h1 {
  font-size: 28px !important;
  font-weight: 700 !important;
}
.text-container-popup-agree {
 
  border-radius: 20px !important;

}
.text-container-popup-agree p {
  color: azure;
  font-size: 25px !important;
  font-weight: 400 !important;
}
.container-2-agree {
text-align: left;
  padding-left: 3% !important; }
  .text-container-popup:hover {
    animation-play-state: paused; /* Pause the animation */
    transform: translate(-50%, -50%); /* Ensure it remains in place */
    opacity: 1; /* Keep it fully visible */
    box-shadow: 
      0 0 5px #ffd951, 
      0 0 10px #ffd951, 
      0 0 20px #da6320, 
      0 0 40px #da6320, 
      0 0 80px #da6320; /* Retain glowing effect */
  }
  .font-p-24{
    font-size: 24px;
    margin-top: 16px;
    font-weight: 500;
  }
  .select-agree{
    width: 100%;
    display: flex
;
    justify-content: center;
  }
  .scroll-container{
    width: 100%;
  }
  .winner-medal{
    display: flex;
    align-items: center;
  }