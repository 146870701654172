@font-face {
  font-family: "Aneba Neue";
  src: url("../fonts/AnebaNeue-Bold.eot");
  src:
    local("../fonts/Aneba Neue Bold"),
    local("AnebaNeue-Bold"),
    url("../fonts/AnebaNeue-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AnebaNeue-Bold.woff2") format("woff2"),
    url("../fonts/AnebaNeue-Bold.woff") format("woff"),
    url("../fonts/AnebaNeue-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Aneba Neue";
  src: url("../fonts/AnebaNeue-Medium.eot");
  src:
    local("../fonts/Aneba Neue Medium"),
    local("AnebaNeue-Medium"),
    url("../fonts/AnebaNeue-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AnebaNeue-Medium.woff2") format("woff2"),
    url("../fonts/AnebaNeue-Medium.woff") format("woff"),
    url("../fonts/AnebaNeue-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Aneba Neue";
  src: url("../fonts/AnebaNeue-Regular.eot");
  src:
    local("../fonts/Aneba Neue"),
    local("AnebaNeue-Regular"),
    url("../fonts/AnebaNeue-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AnebaNeue-Regular.woff2") format("woff2"),
    url("../fonts/AnebaNeue-Regular.woff") format("woff"),
    url("../fonts/AnebaNeue-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Aneba Neue";
  src: url("../fonts/AnebaNeue-SemiBold.eot");
  src:
    local("../fonts/Aneba Neue SemiBold"),
    local("AnebaNeue-SemiBold"),
    url("../fonts/AnebaNeue-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AnebaNeue-SemiBold.woff2") format("woff2"),
    url("../fonts/AnebaNeue-SemiBold.woff") format("woff"),
    url("../fonts/AnebaNeue-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Aneba Neue";
  src: url("../fonts/AnebaNeue-UltraLight.eot");
  src:
    local("../fonts/Aneba Neue UltraLight"),
    local("AnebaNeue-UltraLight"),
    url("../fonts/AnebaNeue-UltraLight.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AnebaNeue-UltraLight.woff2") format("woff2"),
    url("../fonts/AnebaNeue-UltraLight.woff") format("woff"),
    url("../fonts/AnebaNeue-UltraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Whyte";
  src: url("../fonts/Whyte-Bold.eot");
  src:
    local("../fonts/Whyte Bold"),
    local("Whyte-Bold"),
    url("../fonts/Whyte-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Whyte-Bold.woff2") format("woff2"),
    url("../fonts/Whyte-Bold.woff") format("woff"),
    url("../fonts/Whyte-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Whyte";
  src: url("../fonts/Whyte-Black.eot");
  src:
    local("../fonts/Whyte Black"),
    local("Whyte-Black"),
    url("../fonts/Whyte-Black.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Whyte-Black.woff2") format("woff2"),
    url("../fonts/Whyte-Black.woff") format("woff"),
    url("../fonts/Whyte-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Alliance No.1";
  src: url("../fonts/AllianceNo1-Light.eot");
  src:
    local("../fonts/Alliance No.1 Light"),
    local("AllianceNo.1-Light"),
    url("../fonts/AllianceNo1-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AllianceNo1-Light.woff2") format("woff2"),
    url("../fonts/AllianceNo1-Light.woff") format("woff"),
    url("../fonts/AllianceNo1-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Alliance No.1";
  src: url("../fonts/AllianceNo1-Regular.eot");
  src:
    local("../fonts/Alliance No.1 Regular"),
    local("AllianceNo.1-Regular"),
    url("../fonts/AllianceNo1-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AllianceNo1-Regular.woff2") format("woff2"),
    url("../fonts/AllianceNo1-Regular.woff") format("woff"),
    url("../fonts/AllianceNo1-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Alliance No.1";
  src: url("../fonts/AllianceNo1-Bold.eot");
  src:
    local("../fonts/Alliance No.1 Bold"),
    local("AllianceNo.1-Bold"),
    url("../fonts/AllianceNo1-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/AllianceNo1-Bold.woff2") format("woff2"),
    url("../fonts/AllianceNo1-Bold.woff") format("woff"),
    url("../fonts/AllianceNo1-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

:root {
  --litgray: #cbd8e7;
  --yellow: #ffc120;
  /* --yellow: #ff3c3c; */
  --white: #fff;
  --darkblue: #050507;
  --blue: #525197;
  --whate: #525197;
  --opa-white: #798790;
  --light-opc-white: "#D2DEDD";
}

.blu {
  color: var(--blue) !important;
}

.clr-blue {
  color: var(--litgray) !important;
}

.clr-yellow {
  color: var(--yellow) !important;
}
.clr-white {
  color: var(--white) !important;
}
.opc-white {
  color: var(--opa-white) !important;
}
.light-opc-white {
  color: var(--light-opc-white) !important;
}

.bgdark {
  background: linear-gradient(90deg, #040405 0.83%, #24212e 100%);
  border: 1px solid #36363d;
}

/* font-family */
.fw-200 {
  font-weight: 200;
  font-style: normal;
  font-family: "Aneba Neue";
}

.fw-600 {
  font-weight: 600;
  font-style: normal;
  font-family: "Aneba Neue";
}

.fw-500 {
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
}

.fw-n {
  font-style: normal;
  font-family: "Aneba Neue";
}

.fw-bold {
  font-style: bold;
  font-family: "Aneba Neue";
}

.btn {
  font-weight: bold !important;
  font-weight: 600 !important;
}

/* border-radius */
.br-16 {
  border-radius: 16px;
}

* {
  margin: 0;
  padding: 0;
}

/*theme-btn start*/
.themebtn-red {
  width: 112px;
  height: 40px;
  border-radius: 8px;
  background: radial-gradient(100% 100% at 50% 0%, #ffd951 6.77%, #cb5f48 99.48%) !important;
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  border: none;
  border-radius: 8px;
  font-family: "Aneba Neue";
  font-size: 16px;
  line-height: 161.8%;
  text-align: center;
  color: #570f0b !important;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-style: normal;
}

.christmas-btn {
  /* background: linear-gradient(90deg, #b80000, #8b0000) !important; */
  background: radial-gradient(100% 100% at 50% 0%, #ffd951 6.77%, #cb5f48 99.48%) !important;
  color: #fff !important;
}
.christmas-btn:hover {
  /* background: linear-gradient(90deg, #ff4d4d, #b80000) !important; */
  background: radial-gradient(100% 100% at 50% 0%, #cb5f48 6.77%, #ffd951 99.48%) !important;

  color: #ffffff !important;
}
.themebtn-dark {
  width: 112px;
  height: 40px;
  border-radius: 8px;
  /* background: url("../images/dark-btn-bg.png");
  background-size: cover; */
  border-radius: 8px;
  border: 1px solid #848484;
  background: radial-gradient(70.31% 70.31% at 50% 22.66%, #999 0%, #474747 41.67%, #1e1d22 100%);
  box-shadow:
    0px 15px 13px 1px rgba(0, 0, 0, 0.32) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: "Aneba Neue";
  font-size: 16px;
  line-height: 161.8%;
  text-align: center;
  color: #fff !important;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-style: normal;
  border-width: 1px 1px 0 1px;
}

.themebtn-red:hover {
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #8a7f94 0%,
    #4c444e 41.67%,
    #241e35 100%
  );
  box-shadow:
    0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 15px 13px 1px rgba(0, 0, 0, 0.32);
  color: white !important;
}

.themebtn-dark:hover {
  background: radial-gradient(100% 100% at 50% 0%, #ffd951 6.77%, #cb4848 99.48%);
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  box-shadow: none;
  border: none;
  color: black !important;
}
.christmas-dark-btn:hover{
  background: linear-gradient(90deg, #b80000, #8b0000) !important;
  color: #fff !important;
}
/*theme-btn start end*/
body,
html {
  font-family: "Aneba Neue";
  font-size: 17px;
  min-height: 100%;
  height: auto;
  background: #fff;
  overflow-x: hidden;
  background-color: var(--darkblue) !important;
}

.support_btn.d-flex.align-items-center.mb_none_winngng {
  display: none !important;
}

ul li {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  width: 100%;
}

ul {
  padding: 0 !important;
  margin: 0 !important;
}

/* theme-container */
.theme-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* margin class */
.mt-5 {
  margin-top: 5px;
}
.mb-8 {
  margin-bottom: 8px;
}

.mt-8 {
  margin-top: 16px;
}

.mr-16 {
  margin-right: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.ml-16 {
  margin-left: 16px;
}

.mr-13 {
  margin-right: 13px;
}
.m-r-0{
  margin-right: 0px !important;
}
.l-48{
  left: 48% !important;
}
.mr-27 {
  margin-right: 27px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-200 {
  margin-top: 200px;
}
.mt-150 {
  margin-top: 150px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-60 {
  margin-top: 60px;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-32 {
  margin-bottom: 32px;
}

.mt-40 {
  margin-top: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}

.shadow {
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
}
/* padding*/

.bt-padd-10 {
  padding-bottom: 10px !important;
}
/* all site-heading start*/
.f48 {
  font-weight: bold;
  font-family: "Aneba Neue";
  font-style: normal;
  font-size: 48px;
  line-height: 105.8%;
  color: #ffffff;
}
.f25 {
  font-weight: bold;
  font-family: "Aneba Neue";
  font-style: normal;
  font-size: 33px;
  line-height: 105.8%;
  color: #ffffff;
  text-align: center;
}

.f24 {
  font-weight: 600;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 24px;
  line-height: 119.3%;
  color: var(--litgray);
}

.f16 {
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 16px;
  line-height: 119.3%;
  color: #ffffff;
}

.f20 {
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 20px;
  line-height: 119.3%;
  color: var(--litgray);
}
.desk_none_location{
  display: none !important;
}
.f32 {
  font-style: normal;
  font-weight: 500;
  font-family: "Aneba Neue";
  font-size: 32px;
  line-height: 132.3%;
  color: var(--yellow);
}

.f36 {
  font-weight: 600;
  font-size: 36px;
  line-height: 120.6%;
  color: #ffffff;
}

.f64 {
  font-style: normal;
  font-weight: bold;
  font-family: "Aneba Neue";
  font-size: 64px;
  line-height: 105.8%;
  color: #ffffff;
}

.f14 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 118.3%;
  color: #cbd8e7;
}

/* all site-heading end*/

.fixed-top {
  max-width: 1200px;
  margin: 0 auto;
}

/* .header start css */
/* .lobby-bg .btnGetCoins {
	color: #57100C !important;
	text-shadow: none;
} */

.header-wrap {
  position: relative;
  top: 32px;
  /* width: 100%; */
  z-index: 99;
}

button.btnGetCoins {
  color: white !important;
}
.btnGetCoins a {
  text-decoration: none;
  color: #fff5da;
}
button.radeem_btn {
  border-radius: 8px;
  background: #36424a;
  width: 112px;
  height: 40px;
  color: #cbd8e7;
  text-align: center;
  font-family: "AnebaNeue";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin-left: 14px;
  line-height: 132.3%; /* 26.46px */
}

/* section.banner-wrap.lobby-bg.HeaderLobby-wrap nav {
	padding: 8px 114px 8px 32px  !important;
} */

.header-wrap nav {
  padding: 8px 114px 8px 32px;
}

.header-wrap nav ul .nav-link {
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  line-height: 112.3%;
  text-align: center;
  color: #ffffff;
  margin-right: 16px;
  border-bottom: 1px solid transparent;
  position: relative;
}

.header-wrap nav ul .nav-item.active .nav-link:after {
  bottom: 0;
  opacity: 1;
}

.header-wrap nav ul .nav-item.active .nav-link {
  color: #ff0044 !important;
}

.header-wrap nav ul .nav-item:hover .nav-link {
  color: #ffd951 !important;
}

.header-wrap-christmas nav ul .nav-item:hover .nav-link {
  color: #ff3c3c !important;
}

.header-wrap nav ul .nav-item:hover .nav-link:after {
  bottom: 0;
  opacity: 1;
}

.header-wrap nav ul .nav-link:after {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #ffd951;
  opacity: 0;
  -webkit-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}

.header-wrap-christmas nav ul .nav-link:after {
  background-color: #ff3c3c !important;
}

.logo-white {
  display: none;
}
.unauth-logo-up {
  height: 66px;
  width: 70px;
  position: relative;
}
.unauth-logo-up img {
  position: absolute;
  height: 96px;
  width: 78px;
  top: -24px;
  left: -16px;
}
.mb-size {
  width: 141px !important;
}
.user-name-img {
  width: 100% !important;
  border-radius: 0 !important;
  height: 47px !important;
}
/* mobile_menu_wrap */
.mobile_menu_wrap {
  position: fixed;
  width: 400px;
  min-height: 56px;
  background: #2e363c;
  border-radius: 16px;
  padding: 22px;
  z-index: 99;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.BtnOpen span {
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 140.8%;
  text-align: center;
  color: #ffffff;
  padding-left: 10px;
}

button.BtnOpen img,
.BtnClose img {
  width: 21px;
}

button.BtnOpen,
.BtnClose {
  display: flex;
  align-items: center;
  border: none;
  background: no-repeat;
  width: 100%;
  justify-content: center;
}

.menu_list a {
  text-decoration: none;
  margin-bottom: 13px;
  text-align: center;
}

.mobile_menu_wrap ul.footer-list {
  text-align: center;
  margin-bottom: 14px;
  padding-top: 9px;
}

.mobile_menu_wrap ul.footer-list {
  padding-top: 18px !important;
}

.BtnOpen img {
  position: relative;
  top: -3px;
}

.mobile_menu_wrap {
  display: none;
}

/* mobile_menu_wrap seconnd */
.second_menu {
  position: relative;
}

.second_menu .list_nav {
  position: absolute;
  top: -66px;
  z-index: 1;
  right: 0px;
}
/* redem coin mv css */
.redeem_model_wrapp {
  background: rgba(3, 3, 3, 0.8);
}
.modal-open {
  padding-right: 0px !important;
}
.redeem_model_wrapp::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.redeem_model_wrapp::-webkit-scrollbar {
  width: 0 !important; /* Hide the scrollbar */
}

.profile-redeem {
  width: 100%;
  padding: 0px 0px 10px;
}
.profile-redeem .red_mang_coins {
  color: #fff;
  font-family: "AnebaNeue";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 120.6%;
}
.profile-redeem .back_redeem {
  display: flex;
  width: 70px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 104px;
  background-color: transparent;
}
.profile-redeem .back_redeem img {
  width: 24px;
  height: 24px;
}
.profile-redeem .back_redeem p {
  color: #aaa;
  font-family: "AnebaNeue";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0px;
  line-height: 0%;
}
.profile-redeem .redeem_btns button {
  width: 100%;
  height: 56px;
  background-color: #36424a;
  border-radius: 8px;
  color: #cbd8e7;
  text-align: center;
  font-family: "AnebaNeue";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.6%;
  margin-bottom: 16px;
}
.profile-redeem .redeem_btns .redeem_btns_mb_20 {
  margin-bottom: 80px;
}
/* redeem model wrapper */
.model_body_redeem {
  width: 81%;
  margin: auto;
}
.redeem_model_wrapp .modal-content {
  width: 496px;
  border-radius: 0px !important;
  background-color: transparent;
  border: none;
}

.redeem_model_wrapp .modal-body {
  position: relative;
  height: 653px;
  z-index: 1;
  margin-bottom: 16px !important;
  padding-bottom: 0;
}
.design-flex2 {
  display: flex;
}
.redeem_model_wrapp .modal-body::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 16px;
  margin-left: -16px;
  background: linear-gradient(145deg, #4c5360, #000);
  border: 1px solid #36363d;
  opacity: 0.9;
  box-shadow: 0px 11px 19px rgba(6, 6, 7, 0.3);
}

.redeem_model_wrapp .modal-content .modal-body {
  width: 100%;
  margin: 0px auto;
}

.redeem_model_wrapp .modal-dialog {
  border-radius: 16px;
}
.input_redeems {
  display: flex;
  margin-top: 60px;
  flex-direction: column;
  height: 64.295px;
  width: 100%;
  border-radius: 12px;
  border: 2px solid #343d49;
  background: #030303;
  box-shadow: 0px 7px 8px 0px rgba(0, 0, 0, 0.16);
}
.input_redeems label {
  color: #fff;
  font-family: "AnebaNeue";
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  margin: 4px 0px 0px 13px;
}
.input_redeems input {
  background-color: transparent;
  font-family: "AnebaNeue";
  font-weight: 500;
  margin: 4px 0px 0px 13px;
  color: #cbd8e7;
  padding-inline-end: 23px;
}
.redeem_img {
  height: 96px;
  width: 78px;
  margin: 46px auto 20px;
}
.redeem_img img {
  width: 100%;
  height: 100%;
}
.model_body_redeem h1 {
  color: #fff;
  text-align: center;
  font-family: "AnebaNeue";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 120.6%;
}
.model_body_redeem p {
  margin-top: 17px;
  text-align: center;
  font-weight: 500;
  color: #cbd8e7;
}
.rem_acc_div .curr_blnc_blnc {
  font-weight: 600;
}
.rem_acc_div .curr_rem_rem {
  font-weight: 600;
}
.rem_acc_div {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.rem_acc_div p {
  font-weight: 500;
}
.rem_acc_div .curr_blnc p {
  text-align: left;
  margin: 3px 0px !important;
}
.rem_acc_div .curr_rem p {
  text-align: end;
  margin: 3px 0px !important;
  max-width: 159px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.redeem_now_btn {
  margin-top: 88px;
  color: #57100c;
  width: 100%;
  height: 64.295px;
  border-radius: 8px;
  background: radial-gradient(100% 100% at 50% 0%, #ffd951 6.77%, #cb4848 99.48%);
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
}
.redeem-now-christmas{
  background: linear-gradient(90deg, #b80000, #8b0000) !important;
  color: #ffffff !important;
}
.redeem_model_wrapp .modal-footer {
  justify-content: center;
  border-top: none;
}
.redeem_model_wrapp .modal-footer .belowShowdow_div {
  height: 56px;
  width: 81%;
  margin-top: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.redeem_model_wrapp .modal-footer .redeem_succ {
  color: #175328;
  background: #51ff82;
}
.redeem_model_wrapp .modal-footer .redeem_fail {
  display: none;
  width: 100%;
}

.redeem_model_wrapp .modal-footer .redeem_succ {
  width: 100%;
}
.modal-footer .belowShowdow {
  width: 81%;
}

.profile_header:after {
  content: "";
  position: absolute;
  width: calc(100% + 44px);
  height: 1px;
  background: #5f607a;
  left: -22px;
  bottom: -11px;
}

.profile_header .pricebox {
  background: #1a1525;
}

.profile_header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile_menu_wrap .user-profile {
  position: static;
  margin-top: 30px;
  width: 100%;
  background: no-repeat;
  padding: 0;
}

.circal {
  width: 56px;
  height: 56px;
  background: #36424a;
  border-radius: 8px;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

span.circal img {
  margin-right: 0 !important;
}

.mobile_menu_wrap .user-profile {
  margin-bottom: 30px;
}

.mobile_menu_wrap .user-profile li a {
  position: relative;
  height: 56px;
  background: #2c3037;
  border-radius: 8px;
  padding-left: 16px;
  margin-bottom: 8px !important;
  font-weight: 600;
  font-size: 20px;
}

.mobile_menu_wrap .user-profile li a:hover img {
  filter: initial;
}
.mobile_menu_wrap-user-profile-li-a {
  position: relative;
  height: 56px;
  background: #2c3037;
  border-radius: 8px;
  padding-left: 16px;
  margin-bottom: 8px !important;
  font-weight: 600;
  font-size: 20px;
}
.mbl-height-menu{
  max-height: 381px;
  overflow: scroll;
}
.mobile_menu_wrap-user-profile-li-a:hover img {
  filter: initial;
}

.mobile_menu_wrap .user-profile li a:hover {
  color: white;
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #b6acbb 0%,
    #4c444e 41.67%,
    #252231 100%
  );
}

/* search-wrap */
.search-form {
  display: inline-block;
  /* float: right; */
  position: relative;
  width: 100%;
}

.search-wrap {
  display: flex;
  align-items: center;
  position: absolute;
  right: 32px;
  height: 100%;
  /* background: linear-gradient(90deg, #0e0527 0.71%, #0e0724 100%); */
  /* background: linear-gradient(90deg, #040405 0.83%, #24212E 100%); */
  border-left: 1px solid #4e4e63;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  padding-left: 32px;
  z-index: 9;
}

.search-wrap button img {
  width: 20px;
}

.search-wrap input {
  background: no-repeat;
  border: none;
  width: 351px;
  border-bottom: 1px solid #4e4e63;
  margin-right: 35px;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 159.3%;
  color: var(--litgray);
  outline: none;
  padding-bottom: 5px;
}

.search-wrap button {
  border: none;
  background: none;
}

/* header-wrap end css */

/* .banner-wrap start css */
.banner-wrap {
  background: url("../images/banner-bg.png");
  background-size: cover; /* contain */
  background-position: center;
  position: relative;
  /* min-height: 680px; */
}
.banner-wrap-verify {
  /* background-size: cover !important; */
  /* background: url("../images/banner-bg.png"); */
  /* background-position: center; */
  /* position: relative; */
}

s .banner-wrap::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 78px;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000000 100%);
}

.banner-inner {
  background: url("../images/banner.png");
  background-size: cover;
  background-position: center;
  min-height: 496px;
  position: relative;
  margin-top: 60px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-w288 {
  width: 288px;
  height: 64px;
  font-size: 24px;
  line-height: 140.8%;
  font-weight: 600 !important;
  color: #000000;
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
}

.banner-content button {
  margin-left: auto;
  margin-right: auto;
}

.banner-content p {
  max-width: 576px;
  margin: 0 auto;
  font-weight: 500;
  width: 86%;
}

.banner-content h1.f48 {
  font-weight: 600;
}

.banner-content {
  text-align: center;
}

.mb-show {
  display: none;
}

/* .join-with-wrap */
.join-with-wrap .join-button button:hover {
  background: radial-gradient(77.34% 77.34% at 50% 22.66%, #efd183 0%, #ff0044 100%);
  color: #72140e;
}

.join-with-wrap {
  display: none;
}

.join-with-wrap h1 {
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 16px;
  line-height: 105.8%;
  text-align: center;
  color: var(--white);
  margin-bottom: 24px;
  margin-top: 24px;
}

.join-with-wrap .join-button button {
  width: 120px;
  height: 48px;
  background: #160e24;
  opacity: 0.8;
  border-radius: 8px;
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 16px;
  line-height: 159.8%;
  text-align: center;
  color: var(--white);
  border: none;
  margin-right: 13px;
}

.join-with-wrap .join-button button {
  margin-top: 9px;
}

.join-with-wrap .join-button button:last-child {
  margin-right: 0;
}

.join-with-wrap .join-button button img {
  width: 20px;
}

.btn-join img {
  margin-right: 8px;
}

/* .banner-wrap css end */

/* Safe Betting  start*/
.Safe-Betting-wrap {
  background: url("../../../images/dark-cas-bg.png");
  /* background: url("../images/hottest-gambling-bg.png"); */
  background-size: cover;
  background-position: center;
  padding: 128px 0;
}

.safe-betting-img img {
  border-radius: 16px;
}

.safe-betting-img {
  margin-bottom: 116px;
}

.Safe-Betting-right button {
  width: 168px;
  height: 56px;
  font-size: 20px;
  line-height: 138.8%;
  text-align: center;
}

.Safe-Betting-right h1 {
  max-width: 500px;
}

/* Safe Betting  end*/

/* veteran */
.vetran-main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1e1d22;
}
.vetran-main img {
  width: 35px;
  height: 24px;
}
.vetran-main .veteran-flag {
  display: flex;
  width: 310px;
  padding: 16px 0px;
  justify-content: space-between;
}
.vetran-main p {
  margin-bottom: 0px;
}
/* Hundreds-games-wrap start */

.dark-bg-img-section{
  background: url("../images/dark-updated-bg.png");
}
.Hundreds-games-wrap {
  /* background: url("../images/games/games-bg.png"); */
  /* background: url("../images/dark-updated-bg.png"); */
  background-size: cover;
  background-position: center;
  padding: 50px 0px;
  /* padding: 185px 0 191px; */
}
.pb-100{
  padding-bottom: 100px;
}
.pt-100{
  padding-top: 50px;
}

.img-game {
  width: 224px;
  height: 224px;
  background: #1f2932;
  box-shadow: 0px 7px 11px rgba(0, 0, 0, 0.38);
  border: 3px solid #4e4e63;
  border-radius: 16px;
  padding: 3px;
  overflow: hidden;
  margin-right: 20px;
}

.game-card .img-game:hover {
  border: 3px solid #fff;
}

.img-game img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.game-card a {
  text-decoration: none;
}

.game-card:hover h6 {
  color: white;
}

.game-card {
  margin-bottom: 8px;
}

.game-card h6 {
  font-weight: 500;
  font-size: 12px;
  line-height: 144%;
  color: #cbd8e7;
  margin-top: 8px;
}
.cashout-card-text h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 144%;
  color: #687f93 !important;
  margin-top: 8px;
}
.games_wrap {
  position: relative;
}

.games-card-img {
  position: absolute;
  right: 40px;
  top: -30px;
}

.game-card {
  margin-bottom: 25px;
}

.mb_none_btn {
  display: none !important;
}

.support_btn.d-flex.align-items-center.mb_none_btn {
  display: none !important;
}

.game-wrap-row {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  width: 100%;
}

.game-wrap {
  position: relative;
  padding-bottom: 57.7%;
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
}

.game-wrap iframe {
  border-radius: 8px !important;
}

.game-outer {
  max-width: 1200px;
  margin: 0 auto;
}

/* Hundreds-games-wrap enf */
.mb_none_winngng {
  display: none;
}

.btn_iframe {
  padding: 15px 16px;
  background: #241d2f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 133.3%;
  color: #ffffff;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iframe_btn_wrap .btn_iframe:hover {
  background: radial-gradient(77.34% 77.34% at 50% 22.66%, #efd183 0%, #ff0044 100%);
  color: #241d2f;
}

.iframe_btn_wrap {
  margin-top: 30px;
}

.iframe_btn_wrap .btn_iframe img {
  margin-right: 7px;
  width: 24px;
}

/* Instant-deposits */
.Instant-deposits {
  background: var(--darkblue);
  min-height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Instant-deposits p {
  max-width: 728px;
  margin-left: auto;
  margin-right: auto;
}

.brand-logo {
  margin-top: 48px;
}

.skrillPayment {
  margin-right: 50px;
  width: 118px;
  height: 72px;
}

.netellerPayment {
  width: 173px;
  height: 72px;
}
.dive-new-games-wrapper {
  padding: 20px 0px;
}
.winnings_steaks_p {
  color: #d2dedd;

  font-family: "AnebaNeue";
  font-weight: 500;
  font-size: 24px;
}
.play_yelo_link {
  color: #ffc120 !important;
  text-decoration: underline;
  cursor: pointer;
}

/* Instant-deposits end*/

/* Always by Your Side start */
.support_btn {
  margin-top: 32px;
}

.support_btn button {
  width: 168px;
  height: 56px;
  font-size: 20px;
}
.support_btn .cashout-btn {
  font-size: 24px !important;
}
.support_btn .cashout-btn {
  width: 48%;
}
.cash-out-outer-btn button {
  width: 100%;
  height: 64px;
}
.cashout_withdraw_btn_wrap {
  display: flex;
  flex-direction: column;
}
.box-red {
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
}

.box-black {
  box-shadow:
    0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 15px 13px 1px rgba(0, 0, 0, 0.32);
}

.Always-Your-Side {
  background: url("../../../images/always-side.png");
  background-size: cover;
  background-position: center;
  padding-top: 118px;
}

.pb-200 {
  padding-bottom: 200px;
  padding-top: 75px;
}

.Start-Journey {
  margin-top: 139px;
}

.Start-Journey button {
  margin-left: auto;
  margin-right: auto;
}

/* faqs */
.faqs h1 {
  margin-bottom: 64px;
}

.faqs {
  /* background: var(--darkblue); */
  /* background: linear-gradient(180deg, #2c2b2c 0%, #050506 71.35%); */

  padding: 104px 0 94px;
  overflow: hidden;
  z-index: 9;
  position: relative;
}

.faqs p a {
  color: var(--yellow);
  border-bottom: 1px solid var(--yellow);
  text-decoration: none;
}

.faqs p a:hover {
  color: #ff0044;
  border-bottom: 1px solid #ff0044;
}

.faqs .support_btn {
  margin-top: 104px;
  justify-content: center;
}

.faqs h5 {
  color: white;
}

.faqs p {
  color: white;
}

/* Winning Stories */
.Winning-Stories {
  background: url("../images/winning-stories.png");
  background-size: cover;
  background-position: center;
  padding-bottom: 175px;
}

.Winning-Stories .themebtn-dark {
  width: 200px;
}

.Reviews.content-Always {
  padding-top: 288px;
}

/* clinet-reviews */
.clinet-reviews {
  display: flex;
  flex-direction: column;
  max-width: 360px;
  padding: 32px 24px 24px;
  min-height: 184px;
  background: #08060d;
  border: 2px solid #455462;
  border-radius: 16px;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
}

.user-detail h6 {
  margin-bottom: 0;
  text-align: left;
}

.user-detail {
  margin-top: 16px;
  display: flex;
  align-items: self-start;
}

.img-client img {
  margin-left: 8px;
  width: 24px;
  border-radius: 50px;
}

.user-detail p {
  color: #8a939a;
  margin-bottom: 0;
}

.client-content {
  margin-left: 10px;
}
.client-content p {
  font: 12px;
}
.user-detail a {
  display: flex;
  text-decoration: none;
}

.clinet_wrap_details_werp,
.clinet_wrap_inner {
  position: relative;
}

.clinet_wrap_inner:hover::after {
  display: none;
}

/* .clinet_wrap_inner::after {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, rgba(20, 31, 64, 0) 0%, #161027 100%);
  bottom: 0;
  height: 288px;
  width: 100%;
} */

.clinet_wrap_details {
  max-width: 704px;
  margin-left: auto;
  position: absolute;
  top: 0;
  right: -134px;
}

.clint_box {
  position: relative;
  top: -16px;
}

.mt-140 {
  margin-top: 455px;
}

.clinet_wrap_inner .row > [class*="col-"] {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.start-your P {
  max-width: 728px;
  margin-left: auto;
  margin-right: auto;
}

.clinet-reviews p {
  text-align: left;
}

/* about-page */
.about-banner {
  position: relative;
  top: 70px;
}

.about-banner {
  max-width: 768px;
  margin-left: auto;
}

.about-banner img {
  border-radius: 16px;
}

.AboutContent {
  /* background: #1E1E1E; */
  padding-top: 88px;
  padding-bottom: 65px;
}

.about-content-wrap .social-link ul li a:hover {
  color: #ffc120;
}

.about-content-wrap .social-link-christmas ul li a:hover {
  color: #ff3c3c;
}
.about-content-wrap .social-link ul li a {
  text-decoration: none;
  color: white;
}

.about-content-wrap .social-link {
  padding-top: 140px;
}

.about-content-wrap .social-link ul li {
  margin-bottom: 14px;
}

.about-content-wrap .social-link {
  max-width: 365px;
  padding-left: 80px;
}

.about-content-wrap {
  display: flex;
}
.ul_privacy_pol {
  padding-left: 20px !important;
}
.ul_privacy_pol li {
  list-style-type: disc;
  color: #fff;
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 20px;
  line-height: 119.3%;
  margin-bottom: 5px;
}

.about-content-wrap .about-content {
  margin-left: auto;
  /* max-width: 768px; */
}
.about-content-wrap .about-content-maxW {
  margin-left: auto;

  max-width: 768px;
}

.about-content-wrap .about-content p {
  color: white;
}

.about-content-wrap .about-content .f36 {
  margin-top: 32px;
  margin-bottom: 32px;
}

/* Contact-us */
.ContactUs-wrap .ChangeAvatar {
  display: none;
}

.input_wrap textarea.form-control {
  min-height: 168px;
}

.mt-100 {
  margin-top: 100px;
}

.errorbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 118.3%;
  margin-top: 30px;
}

.boxSucess {
  background: #51ff82;
  color: #175328;
}

.boxWrong {
  background: #ff0044;
  color: white;
}

/* .Lobby-bg */
.LobbyBannerMb {
  display: none;
}

.tabs_button ul {
  display: flex;
  flex-wrap: wrap;
}

.btn_tabs {
  cursor: pointer;
  background: #2e363c;
  border-radius: 16px;
  font-weight: 500;
  font-size: 20px;
  line-height: 118.3%;
  text-align: center;
  color: #cbd8e7;
  padding: 16px 15px;
  margin-right: 16px;
  width: 110px;
}
.new-tab-p{
  width: auto !important;
  min-width: 110px !important;
  margin-bottom: 8px;

}

.btn_tabs:hover {
  background: radial-gradient(100% 100% at 50% 0%, #ffd951 6.77%, #cb4848 99.48%);
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  color: #57100c !important;
}
.btn_tabs-christmas:hover{
  background: linear-gradient(90deg, #b80000, #8b0000) !important;
  color: #ffffff !important;
  }
.btn_tabs.active {
  border: 2px solid #ffc120;
}
.btn_tabs-christmas.active{
  border: 2px solid #ff3c3c !important;
  color: #fff !important;
}
button.btn_tabs.btn_tabs-christmas:hover {
  color: white !important;
}
.btn_tabs_provider {
  margin-right: 16px;
  font-size: 24px;
  font-weight: 600;
  background: transparent;
  color: #687f93;
}
.btn_tabs_provider.active {
  border-radius: 16px;

  /* border: 2px solid #ffc120; */
  background: transparent;
  color: #ffc120 !important;
}

.btn_tabs_provider-christmas.active{
  color: #ff3c3c !important;
}
.icon_wrap {
  position: relative;
}

.icon_wrap .icon {
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translateY(-50%);
}

.search_wrap .icon_wrap .form-control {
  width: 232px;
  height: 56px;
  background: #2e363c;
  border-radius: 8px;
  border: none;
  padding-left: 45px;
  font-weight: 500;
  font-size: 20px;
  line-height: 118.3%;
  color: #fff;
}

.Search_wrap {
  display: flex;
  justify-content: space-between;
  margin: 32px 0 0;
  padding-bottom: 18px;
}

/* edit_profile */
.main-wrap-form .themebtn-red:hover {
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #b6acbb 0%,
    #4c444e 41.67%,
    #252231 100%
  );
  box-shadow:
    0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 15px 13px 1px rgba(0, 0, 0, 0.32);
  color: white !important;
}

.main-wrap-form .themebtn-red {
  background: radial-gradient(77.34% 77.34% at 50% 22.66%, #fff6e0 0%, #ffd66e 8.85%, #ff0044 100%);
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  color: #57100c ;
}

.main-wrap-form .themebtn-dark {
  /* background: url("../images/dark-btn-bg.png"); */
  background-size: cover;

  box-shadow:
    0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 15px 13px 1px rgba(0, 0, 0, 0.32);
}

.main-wrap-form .themebtn-dark:hover {
  border-radius: 8px;
  background: radial-gradient(100% 100% at 50% 0%, #ffd951 6.77%, #cb4848 99.48%);
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
}

.main-wrap-form {
  margin-top: 24px;
}

.main-wrap-form button {
  width: 100%;
  height: 64px !important;
}

.main-wrap-form .support_btn {
  margin-top: 64px;
}

.fomBox {
  width: 400px;
  margin: 32px auto;
  padding-bottom: 100px;
}

.input_wrap .form-control {
  background: #030303;
  border: 2px solid #484a4e;
  box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  height: 64px !important;
  font-family: "AnebaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px !important;
  color: #7a7e86;
}

/* .main-wrap-form .form-floating > .form-control:focus ~ label,  
.main-wrap-form .form-floating > .form-control-plaintext ~ label, 
.main-wrap-form .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.99) translateY(-0.3rem) translateX(0.15rem);
} */
.main-wrap-form .form-floating {
  margin-bottom: 8px;
}

.main-wrap-form .form-floating > label {
  font-family: "AnebaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  color: white;
  opacity: 1 !important;
}

.main-wrap-form .form-control:focus {
  color: #cbd8e7 !important;
}

.main-wrap-form .form-control:focus ~ label {
  font-family: "AnebaNeue";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 23px;
  opacity: 1 !important;
  color: #ffffff !important;
  box-shadow: none !important;
}

.form-control:focus {
  color: #fff;
  background-color: #030303 !important;
  border-color: #343d49 !important;
  outline: 0;
  box-shadow: none !important;
}
.search_loby::placeholder {
  color: #cbd8e7 !important; /* Change to your desired color */
}

.btnChangeAvatar {
  width: 136px;
  height: 40px;
  background: #2e363c;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 155.3%;
  text-align: center;
  color: #ffffff !important;
}
/* cashout flow in seetting css */

.total_div_wrapp {
  border-radius: 8px;
  background: #24262c;
  height: 80px;
  margin-bottom: 8px;
}
.total_div_wrapp .tot_blc_coin_wrap {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
  padding-top: 16px;
}
.tot_blc_coin_wrap .tot_blc {
  color: #d2dedd;
  font-weight: 600;
}
.tot_blc_coin_wrap .tot_coins {
  color: #d2dedd;
  font-weight: 600;
}
.tot_blc_coin_wrap .tot_coins img {
  height: 24px;
  width: 24px;
  margin-right: 4px;
}
.total_div_wrapp p {
  margin-left: 5%;
  margin-top: 5px;
  font-weight: 500;
  color: #d2dedd;
}
.blanc-conainer-wrap {
  border-radius: 8px;
  background: #24262c;
  padding: 16px 0px;
}
.blanc-conainer-inner {
  width: 90%;
  margin: auto;
}
.blanc-conainer-inner p {
  width: 85%;
  font-weight: 500;
  color: #cbd8e7;
  margin-top: 8px;
  margin-bottom: 14px;
}
.cur_blc_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cur_blc_wrap p {
  margin-bottom: 0px;
  margin-top: 0px;
  color: #d2dedd;
  font-weight: 600;
}
.headeing-wrap h1 {
  font-weight: 600 !important;
}
.headeing-wrap p {
  font-weight: 500;
}
.blanc-conainer-wrap .setting-img img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.blanc-conainer-wrap .bln-con-img {
  width: 20%;
}
.blanc-conainer-wrap .bln-con-img .cir-cash {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0px auto;
  background: #343d49;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blanc-conainer-wrap .bln-con-img .cir-cash img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.blanc-conainer-wrap .cur-blnce-cash {
  width: 70%;
}
.blanc-conainer-wrap .cur-blnce-cash .golden-pri {
  width: 200px;
  align-items: center;
}
.blanc-conainer-wrap .cur-blnce-cash .golden-pri h1 {
  background: linear-gradient(180deg, #fee994 36.46%, #da6320 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0px;
}
.blanc-conainer-wrap .cur-blnce-cash .golden-pri img {
  width: 32px;
  height: 32px;
  margin-right: 5px;
}
.blanc-conainer-wrap .cur-blnce-cash p {
  margin-bottom: 0px;
}

.confirm-cash-wrapp .cash-bln {
  color: #d2dedd;
  margin: 32px 0px 16px;
}
.confirm-cash-wrapp .cash-equivalent {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.confirm-cash-wrapp .cash-equivalent h2 {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.confirm-cash-wrapp .cash-equivalent p {
  color: #d2dedd;
}
.add_cashout_btnWrap h2 {
  font-weight: 600;
  color: #d2dedd;
}
.add_cashout_btnWrap p {
  color: #d2dedd;
  font-weight: 400;
}
.swich_cashout_btn {
  width: 100%;
  padding: 10px 0px;
  margin-bottom: 16px;
  display: flex;
}
.swich_cashout_btn button {
  padding: 8px 14px;
  margin-right: 10px;
  border-radius: 12px;
  height: auto;
  min-height: 46px;
  background: #2e363c;
  color: #d2dedd;
  border: 2px solid #2e363c !important;
}
.swich_cashout_btn button:hover {
  border: 2px solid #ffc120 !important;
}

.swich_cashout_btn-christmas button:hover {
  border: 2px solid #ff3c3c !important;
}


.swich_cashout_btn button.active {
  border: 2px solid #ffc120 !important;
}

.swich_cashout_btn-christmas button.active {
  border: 2px solid #ff3c3c !important;
}
/* custom drop down */
.form-control-cus_drop {
  min-height: 64px !important;
  height: auto !important;
}
.input-lab-p-abs {
  font-size: 9px !important;
  top: -13px !important;
}
.select-type-cus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 93%;
  padding: 26px 0px 10px;
  margin: 0px auto;
}

.select-cus-input {
  width: 100%;
  background: #030303;
  border: 2px solid #484a4e;
  border-radius: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #7a7e86;
  padding: 0px 0px;
  cursor: pointer;
  z-index: 99;
}
.no-border {
  border: none !important;
}
.options-dev {
  z-index: 99;
  width: 100%;
  left: 0;
}
.options-dev .cus_option {
  border-bottom: 2px solid #484a4e;
  border-top: 2px solid #484a4e;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #cbd8e7;
  padding: 18.5px 10px;
  cursor: pointer;
}
.options-dev .cus_option:hover {
  background: rgba(19, 21, 29, 0.48);
}
.select-cus-input img {
  height: 24px;
  width: 24px;
}
.user_cards_wrap {
  border: 2px solid transparent;
  margin-bottom: 8px;
  cursor: pointer;
}
.user_cards_wrap.active {
  border: 2px solid #ffd951;
  border-radius: 12px;
}
.user_cards_wrap-christmas.active {
  border: 2px solid #ff3c3c;
}

.user_cards_wrap .user_card {
  height: 72px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  background: rgba(19, 21, 29, 0.68);
}
.user_cards_wrap:hover {
  border: 2px solid #ffd951;
  border-radius: 12px;
}
.user_cards_wrap-christmas:hover {
  border: 2px solid #ff3c3c;
}

.user_card .card_img {
  width: 20%;
  justify-content: center;
}
.user_card .card_img img {
  height: 31px;
  width: 44px;
}
.user_card .card_details {
  width: 60%;
}
.user_card .card_details p {
  margin-bottom: 0px;
}
.btn_opc_70 {
  opacity: 0.7 !important;
}
.user_card .card_details h2 {
  margin-bottom: 0px;
}
.user_card .delete_card {
  width: 20%;
  height: 24px;
}
.user_card .delete_card img {
  width: 24px;
}

.cashout_btn_wrap .cashout_btn {
  height: 64px;
  width: 100%;
  border-radius: 8px;
  color: #d2dedd;
  text-align: center;
  border: 1px solid #848484;
  background: radial-gradient(70.31% 70.31% at 50% 22.66%, #999 0%, #474747 41.67%, #1e1d22 100%);
  box-shadow:
    0px 15px 13px 1px rgba(0, 0, 0, 0.32) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-width: 1px 1px 0 1px;
}
.err-mess-wrp {
  height: 56px;
  border-radius: 8px;
  background: #51ff82;
  color: #175328 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "AnebaNeue";
  font-weight: 500;
  font-size: 20px;
}
.err-mess-wrp-red {
  background: #f04 !important;
  color: #fff !important;
}
.err-mess-wrp p {
  margin: 0px;
}
.z-9 {
  position: relative;
  z-index: 9;
}

.btnChangeAvatar span {
  color: white;
}

.ChangeAvatar {
  display: flex;
  align-items: center;
}

.ChangeAvatar img {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 50%;
}

.right_sider {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.header-wrap.headerLobby nav {
  padding: 8px 32px 8px 32px !important;
}

.pricebox {
  width: 128px;
  height: 40px;
  background: #36424a;
  border-radius: 8px;
  position: relative;
  display: grid;
  font-weight: 600;
  font-size: 20px;
  line-height: 129.8%;
  text-align: center;
  color: #cbd8e7;
  align-items: center;
  padding-left: 16px;
}

.pricebox img {
  position: absolute;
  top: -0px;
  left: -16px;
  width: 45px;
}
.pricebox p {
  margin-bottom: 0px;
  margin-left: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}
.pricebox .coin-p-mv {
  margin-bottom: 0px;
  margin-left: 0px !important;
  max-width: 74%;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}

.iconNav {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #36424a;
  cursor: pointer;
}
/* lions image up css */
.lions-logo-up {
  position: relative;
  height: 72px;
  width: 20px;
}
.lions-logo-up img {
  display: block;
  position: absolute;
  top: -22px;
  left: -18px;
  height: 104px;
  width: 82px;
}
.expire_wrapper_cashout {
  display: flex;
  justify-content: space-between;
}
.w49 {
  width: 49%;
}
.expire_wrapper_cashout .expire_wrap_inner {
  width: 55%;
}
.expire_wrapper_cashout .cvv_wrap_inner {
  width: 42%;
}
.img_icon_card_cashout {
  height: 32px;
  width: 48px;
  position: absolute;
  top: 16px;
  right: 16px;
}

/* .iconNav:hover {
	background: radial-gradient(77.34% 77.34% at 50% 22.66%, #EFD183 0%, #FF0044 100%);
} */

.iconNav img {
  width: 24px;
}

.btnGetCoins {
  width: 152px;
  height: 58px;
  /* background: url("../images/coin-btn.png"); */
  background: url("../../../images/getcoinbtn.png");
  background-size: cover;
  border-radius: 16px;
  padding-bottom: 13px;
  font-family: "Whyte";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 176.02%;
  text-align: center;
  color: #fff5da;
  position: relative;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.43);
  z-index: 1;
}

.HeaderLobby-wrap {
  background: url("../images/dark-updated-bg.png");
  width: 100%;
  background-size: cover;
}
.support-clr {
  color: #798790;
}

.user-profile ul {
  margin-top: 16px !important;
}

.user-profile ul li {
  margin-bottom: 10px;
}

.user-profile ul li:last-child {
  margin-bottom: 0;
}

.user-profile ul li a {
  font-weight: 500;
  font-size: 16px;
  line-height: 161.3%;
  color: #cbd8e7;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.user-profile ul li img {
  width: 24px;
  margin-right: 8px;
}

.user-profile ul li {
  margin-bottom: 8px;
}

/* Lobby-page-css */

/* .user-profile */
.profile-wrap {
  position: relative;
}

.user-name {
  display: flex;
  align-items: self-start;
  align-items: center;
}

.user-name h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 117.8%;
  color: #cbd8e7;
  margin-bottom: 0;
  width: 100%;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-content {
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.user-content a {
  font-weight: 500;
  font-size: 12px;
  line-height: 179.8%;
  color: #ffc120;
  text-decoration: none;
}

.user-content a:hover {
  color: #ffc120;
}

.user-profile ul li a:hover {
  color: #ffc120;
}
.user-profile-christmas ul li a:hover {
  color: #ff3c3c !important;
}

/* .user-profile ul li a:hover img {
  filter: invert(100%) sepia(97%) saturate(4801%) hue-rotate(338deg)
    brightness(98%) contrast(110%);
} */

.user-profile {
  position: absolute;
  width: 219px;
  background: #040405;
  border-radius: 16px;
  padding: 24px;
  right: 111px;
  top: 0;
}

.user-name img {
  width: 32px;
  border-radius: 50%;
}

.mr-8 {
  margin-right: 8px;
}

.btnMenu img {
  width: 24px;
}

.lobby_nav {
  display: flex;
  align-items: center;
}

.btnMenu {
  width: 88px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #040405 0.83%, #000 100%);
  border-radius: 16px;
  border: none;
}

.Lobby-bg {
  background: url("../images/Lobby/Lobby-bg.png") !important;
  background-size: cover;
  background-position: center;
}

.Lobby-bg .banner-inner {
  background: none;
}

/* games-page */
.py-160 {
  padding-top: 160px;
  padding-bottom: 160px;
}

.Games-bg {
  background: url("../images/game-bg.png") !important;
  background-size: cover !important;
  background-position: center !important;
  min-height: 100vh;
}

.Games-bg .theme-container {
  height: 100%;
  width: 100%;
}

.banner-wrap.Games-bg {
  min-height: 100vh !important;
}

.banner-wrap.Games-bg:after {
  display: none;
}

/* lobby-bg */

.lobby-bg {
  background: url("../images/Lobby/Lobby-bg.png") !important;
  background-size: cover !important;
  background-position: center;
  padding: 120px 0px;
}

.padd-100-test{
  padding: 100px 0px !important;
}

.head_lobby h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 139%;
  color: #ffc120;
}

.head_lobby-christmas h1 {
  color: #ff3c3c !important;
}

.head_lobby a {
  font-weight: 500;
  font-size: 20px;
  line-height: 145%;
  text-align: right;
  text-decoration-line: underline;
  color: #687f93;
}

.banner-wrap.lobby-bg::after {
  display: none;
}

.head_lobby {
  padding: 56px 0px 32px;
}

.head_lobby a:hover {
  color: #ffc120;
}

.head_lobby-christmas a:hover {
  color: #ff3c3c !important;
}
.item-wrap .game-card h6 {
  color: #687f93;
}
.cashout-card-text h5 {
  color: #687f93 !important;
}
.item-wrap .img-game {
  position: relative;
}

.card_wrap_itme.vew_all {
  display: flex;
}

.card_wrap_itme.vew_all .wrap_item_card:last-child .img-game {
  margin-right: 0;
}

.card_wrap_itme.vew_all .img-game {
  max-width: 224px !important;
  width: 224px !important;
  height: 224px;
}

.card_wrap_itme.vew_all .img-game:last-child {
  margin-right: 0 !important;
}

.empty_duck {
  width: 560px;
}

.empty_duck .empty_du_h1 {
  color: #525197;
  font-size: 48px;
  font-family: "AnebaNeue";
  font-weight: 600 !important;
  font-style: normal;
  font-weight: 400;
}

.empty_duck .empty_du_p {
  color: #cbd8e7;
  font-size: 20px;
  font-family: "AnebaNeue";
  font-weight: 600 !important;
  font-style: normal;
  font-weight: 400;
  line-height: 125.3%;
}

.item-wrap .img_icon {
  position: absolute;
  top: 14px;
  right: 15px;
  z-index: 96;
}

.item-wrap .img_icon.liked .heartfill {
  display: block !important;
}

button.img_icon {
  outline: none;
}

.item-wrap .img_icon.disliked .heartfill {
  display: none;
}

.item-wrap .img_icon .heartfill {
  display: none !important;
}

.img_icon.liked .heart {
  display: none;
}

button.img_icon.like img.heartfill {
  display: block !important;
}

button.img_icon.like img.heart {
  display: none !important;
}

.img_icon {
  background: none;
}

/* .heartfill {
	display: none  !important;
}

.item-wrap .game-card:hover .heart {
	display: none;
}

.item-wrap .game-card:hover .heartfill {
	display: block  !important;
} */

.vew_all .img-game {
  width: auto !important;
}

.row.vew_all > * {
  padding-right: 0;
  padding-left: 0;
}

.row.vew_all .img-game {
  height: 198px;
  margin-right: 11px;
}

.main_lobby {
  background: url("../images/dark-updated-bg.png");
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

.main_lobby::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 688px;
  width: 100%;
  /* background: linear-gradient(180deg, rgba(22, 14, 37, 0) 36.69%, #231b31 100%); */
  background: linear-gradient(180deg, rgb(0 0 0 / 0%) 36.69%, #000000 100%);
  transform: matrix(1, 0, 0, -1, 0, 0);
  z-index: -1;
}

.main_lobby .inner {
  position: relative;
}

.main_lobby .inner::after {
  content: "";
  background: url("../images/Lobby/gamesshadowleft.png");
  width: 389px;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
}

.head_lobby {
  position: relative;
  /* z-index: 9; */
}

.main_lobby .inner::before {
  content: "";
  background: url("../images/Lobby/gamesshadowleft.png");
  width: 389px;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1;
  transform: rotateY(180deg);
}

.claim_now {
  text-align: center;
  padding-top: 211px;
  padding-bottom: 91px;
}

.claim_now button {
  margin-top: 39px;
}

.claim_now p {
  max-width: 728px;
  margin-left: auto;
  margin-right: auto;
}

/* footer start-css */
.footer_second p {
  font-size: 16px;
  line-height: 119.8%;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0;
}

.footer_second {
  background: #2e363c;
  display: none;
  min-height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  padding: 0 22px;
}

.logo_footer {
  width: 45px;
  height: 60px;
  display: none;
}

.footer-wrap {
  background: #040406;
  padding: 114px 0 104px;
}

.footer-list li a {
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 20px;
  line-height: 105.8%;
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 25px;
  display: inline-flex;
}

.footer-list li a:hover {
  color: #ffd951;
}
.footer-list-christmas li a:hover {
  color: #ff3c3c;
}

.addree-info {
  margin-top: 20px;
}
.footer-bottom {
  margin-top: 70px;
}
.footer-bottom .f-left-p {
  text-align: right;
}

.footer-bottom p {
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 20px;
  line-height: 105.8%;
  color: #ffffff;
  margin-bottom: 0;
}
.footer-bottom p img {
  margin-right: 10px;
  margin-top: -4px;
}
.footer-wrap .btnsupport {
  width: 220px;
  height: 56px;
  background: #2e363c;
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
  font-style: normal;
  font-family: "Aneba Neue";
  font-size: 20px;
  line-height: 133.3%;
  text-align: center;
  color: #ffffff;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-wrap .btnsupport img {
  width: 25px;
  margin-right: 7px;
}

.footer-wrap .btnsupport:hover {
  background: radial-gradient(100% 100% at 50% 0%, #ffd951 6.77%, #cb4848 99.48%);
  box-shadow: none;
  border: none;
  color: black !important;
}

.footer-wrap .btnsupport-christmas:hover{
color: #ffffff !important;
background: linear-gradient(90deg, #e43d3d, #8b0000);
}

/* placeholder */
::-webkit-input-placeholder {
  /* Edge */
  color: #4e4e63;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #4e4e63;
}

::placeholder {
  color: #4e4e63;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  /* -moz-appearance: textfield; */
  appearance: textfield;
}

.card_wrap_itme.vew_all {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-gap: 4px;
}

.card_wrap_itme.vew_all .wrap_item_card {
  margin: 0 auto;
}

.card_wrap_itme.vew_all .img-game {
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 2px solid #484a4e !important;
  -webkit-text-fill-color: #cbd8e7 !important;
  -webkit-box-shadow: 0 0 0px 1000px #030303 inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

/* setting-wrap-main */
.setting-wrap-main {
  background: url(../images/dark-updated-bg.png) !important;
  background-size: cover !important;
  background-position: center !important;
}
.setting-h1 {
  margin-bottom: 64px;
  color: #798790;
}
.refer-bg-active {
  background: #000 !important;
}

.Tabs-wrap button img {
  width: 20px;
  height: 24px;
  margin-right: 8px;
}

.Tabs-wrap .nav-tabs {
  border-bottom: 0;
  margin-bottom: 20px !important;
}

.Tabs-wrap .nav-tabs .nav-link {
  padding: 16px 16px;
  height: 56px;
  background: #2e363c;
  border-radius: 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 118.3%;
  color: #d2dedd;
  margin-right: 16px;
  border: 2px solid transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Tabs-wrap .nav-tabs .nav-link.active {
  border: 2px solid #ffc120 !important;
  background: #2e363c;
  color: #d2dedd;
}

.Tabs-wrap .nav-tabs-christmas .nav-link.active {
  border: 2px solid #ff3c3c !important;
}

.Tabs-wrap .nav-tabs .nav-link:hover {
  border: 2px solid #ffc120 !important;
}

.Tabs-wrap .nav-tabs-christmas .nav-link:hover {
  border: 2px solid #ff3c3c !important;
}

/* switch style */
.switch input {
  display: none;
}

.switch {
  display: inline-block;
  width: 50px;
  height: 25px;
  transform: translateY(50%);
  position: relative;
}

/* Style Wired */
.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 24px;
  box-shadow:
    0 0 0 4px #484a4e,
    0 0 4px #484a4e;
  cursor: pointer;
  border: 0px solid transparent;
  overflow: hidden;
  transition: 0.4s;
  background-color: #2c3037;
}

.slider:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #798790;
  border-radius: 30px;
  transform: translateX(-24px);
  transition: 0.4s;
}

input:checked + .slider:before {
  transform: translateX(24px);
  background: #f2d052;
}

input:checked + .slider {
  box-shadow:
    0 0 0 4px #4e4e63,
    0 0 4px #4e4e63;
  background: #db8520;
}

/* Style Flat */
.switch.flat .slider {
  box-shadow: none;
}

.switch.flat .slider:before {
  background: #fff;
}

.switch.flat input:checked + .slider:before {
  background: white;
}

.switch.flat input:checked + .slider {
  background: limeGreen;
}

/* switch style end */

/* .setting-wrap{
	padding-bottom: 63px;
} */
.switch_wrap_main .switch-content {
  max-width: 343px;
}
.switch_wrap_main .switch-content h1 {
  color: #d2dedd;
}
.switch_wrap_main .switch-content p {
  color: #d2dedd;
}

.switch_wrap_main .switch-content .f20 {
  font-weight: 600 !important;
}

.Notifications_switch_wrap {
  max-width: 440px;
  margin: 0 auto;
}

.switch_wrap_main {
  margin-bottom: 28px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

/* ReferFriend_content */
.ReferFriend_content {
  max-width: 568px;
}

.ReferFriend_content .f48 {
  font-weight: 600 !important;
}

.ReferFriend_content p,
.get_start_content p {
  color: #cbd8e7;
}

.get_start_content {
  max-width: 420px;
  position: relative;
  z-index: 10;
  min-height: 600px;
}

.mt-64 {
  margin-top: 64px;
}

.get_start_content .f20 {
  font-weight: 600;
}

.copy_Wrap {
  position: relative;
}

.copy_Wrap .btn-copy img {
  margin-right: 0;
}

.copy_Wrap .btn-copy {
  position: absolute;
  width: 56px;
  height: 60px !important;
  right: 1px;
  top: 1.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2e363c;
  border-radius: 0px 11px 11px 0px;
}

.copy_Wrap .btn-copy:hover {
  background: radial-gradient(100% 100% at 50% 0%, #ffd951 6.77%, #cb4848 99.48%);
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
}
.copy_Wrap .btn-copy-christmas:hover{
  background: linear-gradient(90deg, #ff4d4d, #b80000) !important;
}
.theme-container.tabs-menu ul li {
  padding-bottom: 2px;
}

.get_start_wrap {
  position: relative;
  min-height: 500px;
}

.ReferFriend_man_img img {
  position: relative;
  z-index: -1;
}

.ReferFriend_man_img {
  position: absolute;
  max-width: 816px;
  bottom: -62px;
  right: -161px;
}

.get_start_wrap {
  position: relative;
}
.ref-open-email-btn {
  margin-top: 20px;
}

.get_start_wrap::after {
  content: "";
  bottom: -64px;
  position: absolute;
  background: linear-gradient(180deg, rgba(20, 17, 48, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  width: 10000px;
  height: 464px;
  z-index: 9;
  left: -100%;
}

.scrollable-container {
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: transparent transparent;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For IE and Edge */
}

.scrollable-container::-webkit-scrollbar {
  width: 0.5em;
  /* Adjust the width as needed */
}

.scrollable-container::-webkit-scrollbar-track {
  background: transparent;
  /* Set the background color as needed */
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* Set the thumb color as needed */
}

.game-card:hover .img-game img {
  transition: all ease-in-out 0.5s;
}

.game-card:hover .img-game img {
  transform: scale(1.1);
}

.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  background: none !important;
}

.text_lt {
  color: #cbd8e7 !important;
}
.text_lt_bill {
  color: #d2dedd !important;
}

.custom_table {
  margin: 31px 0px;
  min-height: 536px;
  background: #040405;
  border-radius: 16px;
  padding: 42px 48px;
}

.custom_table {
  min-height: 436px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.giro-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
}

.giro-table .giro-table tr {
  border: none;
}

.giro-table th .Invoice_link img {
  width: 24px;
  margin-right: 8px;
}

a.Invoice_link {
  padding: 0 !important;
}

.Invoice_link {
  font-weight: 600;
  font-size: 16px !important;
  line-height: 155.5%;
  color: #aab3d2 !important;
  vertical-align: middle;
  text-decoration: none;
}

.giro-table th {
  background: #26262c !important;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 155.5%;
  color: #d2dedd !important;
  padding: 8.7px 16px !important;
  vertical-align: middle;
  text-decoration: none;
}

.giro-table thead {
  border-radius: 8px !important;
}
.giro-table th {
  width: 14.4%;
}
.giro-table tbody tr td {
  background: #2c3037 !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 158.5%;
  color: #d2dedd;
}
.giro-table tbody tr td {
  max-width: 13.6%;
  width: 16.6%;
  padding: 8.7px 16px !important;
}

.br-r-8 {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.bl-r-8 {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.giro-table td {
  padding: 16px !important;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 !important;
  /* Remove default borders */
  border-top: none !important;
  /* Add custom border */
  border-bottom: none !important;
  /* Add custom border */
}

td:first-child,
th:first-child {
  border-left: none !important;
  /* Add custom border */
}

td:last-child,
th:last-child {
  border-right: none !important;
  /* Add custom border */
}

.Billing_History_wrap {
  background-image: url("../images/dark-updated-bg.png");
}

.search_wrap.billing_search {
  width: 160px;
  margin-left: auto;
}

.search_wrap.billing_search .form-control {
  width: 160px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 184.52%;
  color: #d2dedd !important;
  border-radius: 8px;
  border: 2px solid #484a4e;
  background: #2e363c;
  margin-left: auto;
}

.search_wrap.billing_search .form-control::placeholder {
  color: #d2dedd !important;
}

/* redio_wrap  . */

.redio_wrap input[type="checkbox"],
.redio_wrap input[type="radio"] {
  appearance: none;
}
.redio_wrap label {
  text-decoration: underline;
  color: #f2d052;
}

.redio_wrap input[type="checkbox"] + label {
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 158.5%;
  text-decoration-line: underline;
  color: #f2d052;
}

.redio_wrap input[type="checkbox"] + label::before,
.redio_wrap input[type="radio"] + label::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  aspect-ratio: 1;
  border: 2px solid #4048b7;
  border-radius: 50%;
  position: absolute;
  background: #171b49;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  opacity: 1;
  transition: all 0.3s;
}

.redio_wrap input[type="checkbox"] + label::after,
.redio_wrap input[type="radio"] + label::after {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  aspect-ratio: 1;
  border: 2px solid #ffdb1e;
  background: #ffdb1e;
  border-radius: 50%;
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.3s;
}

.redio_wrap input[type="checkbox"]:checked + label::after,
.redio_wrap input[type="radio"]:checked + label::after {
  opacity: 1;
}

.load_more {
  width: 239px;
  height: 48px;
  margin-top: 100%;
  background: #36424a;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 133.6%;
  text-align: center;
  color: #cbd8e7;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.load_more:hover {
  background: #26262c;
  color: #fff;
}

/* Notifications_box */
.Notifications_box {
  padding: 28px 16px 32px;
  background: #1a1a1f;
  width: 488px;
  position: absolute;
  right: 16px;
  border-radius: 0px 0px 8px 8px;
  top: -1px;
  z-index: -1;
}

.Notifications_box .Notifications_head h4 img {
  width: 24px;
  cursor: pointer;
  margin-right: 14px;
  margin-top: -2px;
}

.Notifications_box .Notifications_head h4 {
  font-weight: 600;
  color: white;
  position: relative;
  margin-bottom: 0px;
  /* left: -27px; */
}
.close-notification {
  position: absolute;
  left: -10px;
}
.my-notifications-heading {
  margin-left: 15px !important;
}
.MarkAll h5 img {
  width: 24px;
}

.MarkAll h5 {
  color: #aaaaaa;
  display: flex;
  align-items: center;
}

.Notifications_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.MarkAll h5 {
  margin-bottom: 0;
}

.MarkAll h5 img {
  /* margin-right: 8px; */
}

.MarkAll {
  display: flex;
  width: 185px;
  align-items: center;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer !important;
}

.SettingSlid {
  width: 24px;
}

.Notifications_details {
  padding: 16px;
  background: #2c3037;
  border-radius: 8px;
  display: flex;
  align-items: self-start;
  margin-bottom: 8px;
}

.Notifications_details img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  object-fit: cover;
}

.Notifications_details_content {
  margin-left: 16px;
}

.Notifications_details_content p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 129.6%;
  color: #cbd8e7;
}

.mt-28 {
  margin-top: 28px;
}

.Notifications_details_content p a {
  color: #ffc120;
  text-decoration: underline;
}

.Notifications_details_content-christmas p a {
  color: #ff3c3c !important;
 
}


.Notifications_details_content .time_text {
  font-weight: 500;
  font-size: 12px;
  line-height: 140.1%;
  color: #696a86;
  margin-bottom: 0;
  margin-top: 5px;
}

.icon_box {
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  background: #36424a;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon_box img {
  width: 24px;
  height: 24px;
}

.btn_sell {
  height: 48px;
  background: #36424a;
  border-radius: 8px;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 133.6%;
  text-align: center;
  color: #cbd8e7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_sell:hover {
  background: radial-gradient(100% 100% at 50% 0%, #ffd951 6.77%, #cb4848 99.48%);
  color: black;
}

.btn_sell-christmas:hover {
  background: linear-gradient(90deg, #b80000, #8b0000) !important; 
  color: rgb(255, 255, 255) !important;
}

a.notifaction_btn.toggleNotifications .iconNav {
  background: #1f1928;
  border: 2px solid #4e4e63 !important;
}

.form_search {
  position: relative;
}

.form_search .search_box {
  width: 328px;
  height: 40px;
  background: #36424a;
  border: 2px solid #798790;
  border-radius: 100px;
}
.form_search .search_box21 {
  width: 230px !important;
 
}


.search_wrap_sceond .form_search {
  position: absolute;
  right: 15px;
  top: 0;
}
.new-back-lobby-mbBtn2 {
  display: none;
}
.new-back-lobby-mbBtn {
  display: flex;
  position: absolute;
  bottom: 0;
  height: 12dvh;
  width: 100%;
  display: flex;
  background-color: black;
  left: 0;
  align-items: center;
  /* justify-content: center; */
  padding-left: 2%;
}
.new-back-lobby-mbBtn img {
  width: 20px;
  height: 20px;
}
.new-back-lobby-mbBtn p {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.3%;
  margin-bottom: 0% !important;
  margin-left: 2%;
}
.search_wrap_sceond {
  position: relative;
  /* margin-right: 10px; */
}
.user-name-img69 {
  width: 37px !important;
  height: 37px !important;
  border-radius: 50% !important;
  margin-bottom: 34% !important;
}

.form_search .icon_search {
  position: absolute;
  width: 18px !important;
  left: 11px;
  top: 50%;
  transform: translateY(-50%);
}

.form_search .search_box {
  padding-left: 35px;
  font-weight: 500;
  font-size: 14px;
  line-height: 184.52%;
  color: #aab3d2;
}

a.close_search {
  position: absolute;
  right: 42px;
  top: 50%;
  transform: translateY(-50%);
}

a.close_search .icon_search {
  width: 24px !important;
}

.form_search {
  display: none;
}

a.search_wrap_sceond.active .form_search {
  display: block;
}

.mobile_notiffaction {
  display: none;
}

.user-profile.active .mobile_notiffaction {
  display: block;
}

.user-profile.active .mobile_notiffaction .Notifications_box {
  position: static;
  width: 100%;
  background: none;
  padding: 0;
}

.user-profile.active ul {
  display: none;
}

.user-profile.active .mobile_notiffaction .Notifications_box .inner_Wrap {
  height: 300px;
  overflow-y: auto;
}

.user-content a {
  width: 100%;
}

/* .mobile_menu_wrap { 
    padding: 16px 23px !important;
} */
.mobile_notiffaction .theme-container.z-9 {
  padding: 0;
}

.user-profile.active .mobile_notiffaction .Notifications_box {
  margin-top: 0;
}

.user-profile.active .mobile_notiffaction .Notifications_box .Notifications_head {
  display: none;
}

.user-profile.active .Notifications_details {
  padding: 10px;
}

.user-profile.active .btn_sell_Wrap {
  margin-top: 40px;
}

img.pluz_icon {
  right: 0 !important;
  position: absolute;
  left: auto;
  width: 40px;
}

.profile_header .pricebox {
  background: #2c3037;
  width: 155px;
  padding-left: 34px;
  text-align: left;
}

.LobbyBannerMb {
  display: none;
}

/* scrollbar */
.scroll_bar::-webkit-scrollbar {
  width: 8px !important;
}

.scroll_bar::-webkit-scrollbar-track {
  background: #040405;
  border-radius: 10px;
}

.scroll_bar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #36424a;
}

.table {
  width: 100%;
  table-layout: fixed;
}

thead {
  display: inherit;
  width: 100%;
}

.scroll_bar {
  display: block;
  max-height: 250px;
  overflow-y: scroll;
}

.w-153 {
  width: 14.8%;
}

.w-19 {
  width: 19%;
}

.w-18 {
  width: 101px;
}
.w-115px {
  /* width: 115px !important; */
}

th.w-18.pl-0 {
  padding-left: 0 !important;
}

th.pl-0,
td.pl-0 {
  padding-left: 0 !important;
}

th.pl-custom {
  padding-left: 37px !important;
}

/* view_all_page_notifaion */
.view-all-wrap .Notifications_box .Notifications_head h4 {
  display: none;
}

.view-all-wrap .Notifications_box {
  background: no-repeat;
  padding: 0;
  position: relative;
}

.view-all-wrap .Notifications_box {
  background: no-repeat;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  right: unset;
}

.view-all-wrap {
  position: relative;
  z-index: -1;
}

.view-all-wrap {
  padding-bottom: 64px;
  width: 464px;
  margin: 0 auto;
}

.view-all-wrap .Notifications_box .Notifications_head {
  justify-content: flex-end;
}

.table_width {
  overflow: auto;
}

.table_width::-webkit-scrollbar {
  width: 4px !important;
  height: 10px !important;
  position: relative;
  bottom: -12px;
}

.table_width::-webkit-scrollbar-track {
  background: #252427;
  border-radius: 10px;
}

.table_width::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #36424a;
}

/* .search_result */
.btn_select {
  background: #2e363c;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 118.3%;
  text-align: center;
  color: #d2dedd;
  border: 2px solid #2e363c;
  cursor: pointer;
  margin-right: 10px;
}

.search_result {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.item_neme_button {
  display: flex;
  flex-wrap: wrap;
}
.item_neme_button button {
  margin-bottom: 5px;
}

.search_result {
  margin-bottom: 64px;
}

.search_result .item_text {
  margin-right: 45px;
}
.search_result .item_text h1 {
  color: #d2dedd;
}

.btn_select:hover {
  border: 2px solid #585871 !important;
}

.btn_select img {
  margin-left: 10px;
  height: 24px;
  width: 24px;
}

/* .a_to_z .btn_tabs {
  border: 2px solid #160e24;
} */

.a_to_z .btn_tabs:hover {
  /* border: 2px solid #ffc120; */
  background: radial-gradient(100% 100% at 50% 0%, #ffd951 6.77%, #cb4848 99.48%);
  color: #57100c !important;
  box-shadow: none;
}

.a_to_z .search_wrap .icon_wrap .form-control::placeholder {
  color: #687f93 !important;
}

.head_lobby a.view-less {
  color: #ffc120;
}

.head_lobby-christmas a.view-less {
  color: #ff3c3c;
}

.mt-70 {
  margin-top: 70px;
}

.filter_list ul {
  display: flex;
  flex-wrap: wrap;
}

.filter_list ul li button {
  display: flex;
  width: 24px;
  height: 32px;
  color: #687f93;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 139%;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 16px;
  text-decoration: none;
  background: none;
}

.filter_list ul li button:hover {
  color: #ffc120;
  text-decoration: none;
}
.filter_list ul li button.active {
  color: #ffc120;
  text-decoration: none;
}

.filter_list-christmas ul li button:hover {
  color: #ff3c3c !important;
}
.filter_list-christmas ul li button.active {
  color: #ff3c3c !important;
}
/* no game found message css lobby */
.no_games_found_h1 {
  font-size: 28px;
  font-weight: 600;
  font-family: "AnebaNeue";
  color: #ffc120;
}
.no_games_found_h1-christmas {
  color: #ff3c3c !important;
}
.main_lobby:hover .inner::after,
.main_lobby:hover .inner::before {
  z-index: -1;
}
.game-card:hover {
  position: relative;
  z-index: 999;
}
.lobby-banner-winning {
  position: absolute;
  top: 24%;
  width: 100%;
}
.lobby-banner-winning h1 {
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 105.8%;
  color: #fff;
  text-align: center;
  font-family: "AnebaNeue";
  font-weight: 600;
}
.lobby-banner-winning p {
  color: #cbd8e7;
  text-align: center;
  font-family: "AnebaNeue";
  font-weight: 500;
  padding: 16px 0px;
  margin-bottom: 0px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 119.3%;
}
.lobby-banner-winning p {
  margin: auto;
  width: 45%;
}
.clr-btn-brown {
  color: #72140e ;
}
.brp-provider {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 37%;
}
/* loader for preloading */

.loader-main-wrapper {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-main-wrapper-game-page {
  width: 100dvw;
  height: 70dvh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-main-wrapper img , .loader-main-wrapper-game-page img {
  width: 80px;
  height: 110px;
  transform-style: preserve-3d;
  animation: flipY 1s infinite reverse;
}

@keyframes flipY {
  0%,
  100% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
}
.pricebox-floating {
  display: flex !important;
  background-color: rgb(46, 54, 60) !important;
  height: 50px !important;
}

.mobile_menu_wrap2 {
  display: none;
}

.search_box::placeholder {
  color: white !important;
}

/* Jackpot Prize Text Radiate */
/* @keyframes radiate {
  0% {
    box-shadow:
      0 0 10px rgba(255, 223, 0, 0.8),
      0 0 20px rgba(255, 223, 0, 0.8),
      0 0 30px rgba(255, 223, 0, 0.8);
  }
  50% {
    box-shadow:
      0 0 20px rgba(255, 223, 0, 0.6),
      0 0 40px rgba(255, 223, 0, 0.6),
      0 0 60px rgba(255, 223, 0, 0.6);
  }
  100% {
    box-shadow:
      0 0 10px rgba(255, 223, 0, 0.8),
      0 0 20px rgba(255, 223, 0, 0.8),
      0 0 30px rgba(255, 223, 0, 0.8);
  }
} */

/* @keyframes glow {
  from {
    text-shadow: 
      0 0 30px #ffd700, 
      0 0 90px #ffae00,  
      0 0 210px #ff0f00;
  }

  to {
    text-shadow: 
      0 0 50px #fff700, 
      0 0 110px #ffd100, 
      0 0 230px #ff4d00;
  }
}
.radiate-effect {
  color: #ffd700; 
  animation: glow 1.5s ease-in-out infinite alternate;
  will-change: text-shadow; 
  transform: translateZ(0); 
} */

@keyframes glow {
  from {
    text-shadow: 0 0 10px #ffd700, 0 0 20px #ffd700, 0 0 30px #ffae00;
  }
  to {
    text-shadow: 0 0 20px #fff700, 0 0 30px #ffea00, 0 0 40px #ffd100;
  }
}

.radiate-effect {
  color: #ffd700; 
  animation: glow 1.5s ease-in-out infinite alternate;
  will-change: text-shadow; 
  transform: translateZ(0); 
}



.act-jack-span img{
height: 40px !important;
width: 40px !important;
margin-bottom: 9px !important;
}
.font-bold{
  font-weight: 700;
}
.scroll-container2{
  height: 400px;
    overflow-y: scroll;
    display: block;
}
.scroll-container2::-webkit-scrollbar {
  width: 8px !important;
  /* display: none; */
}

.scroll-container2::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
  /* display: none; */

}

.scroll-container2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #828282;
  /* display: none; */

}
.pricebox-23 img {
  width: 51px;
  top: -6px;
  left: -17px;
}
