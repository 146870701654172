body {
  font-family: "AnebaNeue";
  /* font-weight: bold;  */
  overflow-x: hidden;
}
/* padding */
.padd-top-56px {
  padding-top: 56px;
}
/* margins */
.mtop-10 {
  margin-top: 10px;
}

.mbtm-16 {
  margin-bottom: 16px;
}

.mtop-20 {
  margin-top: 20px;
}

.mtop-35 {
  margin-top: 29.5px;
}

.mtop-100 {
  margin-top: 100px;
}

.mbot-10 {
  margin-bottom: 10px;
}

.mbot-20 {
  margin-bottom: 20px;
}

.h-100 {
  height: 100%;
}

/* flex-c */
.d-flex-ac {
  display: flex;
  align-items: center;
}

/* fonsts */
.f-36-semibold {
  font-family: "AnebaNeue";
  font-weight: 600;
  line-height: 120.6%;
  font-size: 36px;
}

.f-16-font-medium {
  font-family: "AnebaNeue";
  font-size: 16px;
  font-weight: 500;
  line-height: 156.8%;
}

.f-14-font {
  font-family: "AnebaNeue" !important;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}
/* ======================= signup header ====================== */
.signup_header_conatiner {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: max-content;
  padding-top: 30px;
}
.signup_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.signup_header .signup_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76px;
  height: 96px;
  /* background: linear-gradient(90deg, #0e0527 0.83%, #0e0724 100%); */
  border-radius: 16px;
}
.signup_header .signup_logo img {
  width: 76px;
  height: 96px;
  cursor: pointer;
}
.signup_header .signup_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 88px;
  background: #030303;
  border-radius: 16px;
}
.signup_header .signup_btn .signup_btn_button {
  width: 120px;
  height: 40px;

  border-radius: 8px;
  background: url("./HomeAssets/assets/images/dark-btn-bg.png");
  background-size: 100%; /* Stretch the background to cover the button */

  background-repeat: no-repeat;
  background-size: cover;
  font-family: "AnebaNeue";
  font-weight: 600;
  font-size: 16px;
  line-height: 138.8%;
  text-align: center;
  color: #d2dedd;
}
/* ======================= signup page css ===================== */
.wrapper-create-user {
  width: 100%;
  height: 100%;
  min-height: 100dvh;
  /* background-image: linear-gradient(
      180deg,
      #0e1c51 0%,
      #170e26 52.35%,
      #161046 100%
    ),
    url("../assets/images/create-bg-layers.png"); */
  background-image: url("../assets/HomeAssets/assets/images/dark-updated-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 1 !important;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  color: #ffff !important;
}

.wrapper-create-user .create-user {
  width: 100%;
  height: 100dvh;
  min-height: 900px;
  /* background-image: url("../assets/images/create-bg-layers.png") ; */
  background-size: cover;
  background-repeat: no-repeat;
  /* position: absolute; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-user .create-user-form {
  box-sizing: border-box;
  width: 792px;
  min-height: 480px;
  border: 1px solid #36363d;

  background: linear-gradient(145deg, rgba(76, 83, 96, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%);

  box-shadow: 0px 11px 19px -4px rgba(6, 6, 7, 0.3);
  border-radius: 16px;
}

.create-user-form .create-user-inner {
  height: 100%;
  width: 100%;
}

.d-flex-jc {
  display: flex;
  justify-content: center;
}

.cus-padding-tb-40 {
  padding: 40px 0px;
}

.d-flex-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex-sb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.field-h-box {
  margin-bottom: 32px;
}

.field-h-box h1 {
  color: #d2dedd;
}
.field-h-box .set-pass-h1 {
  margin-top: 73px;
}

.input-box {
  width: 400px;
  height: 64px;
  position: relative;
}

.eyeShow-img {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 21px;
  right: 10px;
  cursor: pointer;
  overflow: hidden;
}

.input-box .input-box-field input {
  font-family: "AnebaNeue";
  font-weight: 500;
  font-size: 16px;
  line-height: 159.3%;
  border-radius: 12px;
  border: 2px solid #484a4e;
  background: #030303;
  box-shadow: 0px 7px 8px 0px rgba(0, 0, 0, 0.16);
  position: relative;
  color: #d2dedd;
}
/* 
.input-box .input-box-field input:-webkit-autofill {
    color: red !important; 
    background: #161126 !important;

}  */

.input-box .star-password input {
  padding-inline-end: 50px;
}

.input-box .input-box-field label {
  color: #fff;
  font-family: "AnebaNeue";
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
}

.input-box .input-box-field input:focus + label {
  color: #fff;
  /* Change the color to your desired color */
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-wrapper input {
  padding: 1rem 0.75rem !important;
  padding-top: 1.625rem !important;
  padding-bottom: 0.625rem !important;
}
.react-datepicker-wrapper input::placeholder {
  color: #7a7e86;
}
.date-picker-cus {
  width: 100% !important;
  /* min-width: 400px; */
  height: 64px;
}
.date-picker-cus-lable label {
  padding-top: 10px !important;
}
.date-picker-cus-lable-editProfile label {
  padding-top: 6px !important;
  font-size: 10px !important;
}

.input-box .input-box-inner {
  margin: 0px 10px;
}

.input-box .span-password-error {
  position: absolute;
  top: 67px;
  right: 0;
  font-family: "Alliance No.1";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #ff0044;
}

.input-box .input-box-inner span {
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  margin-left: 1px;
}

.input-box .input-box-inner input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: #cbd8e7;
}

.create-box-btn {
  display: flex;
  justify-content: space-between;
  margin-top: 170px;
}

.create-user-btn-wrap {
  margin-top: 86px;
}

.birthday-btn-wrap {
  margin-top: 150px;
}
.btn-wrap-mb-64 {
  margin-bottom: 64px;
}

input.date-calendar-hide::-webkit-calendar-picker-indicator,
input.date-calendar-hide::-webkit-inner-spin-button,
input.date-calendar-hide::-webkit-clear-button {
  display: none;
  -webkit-appearance: none;
}

.create-box-btn .cannel-btn {
  font-size: 24px;
  line-height: 138.8%;
  text-align: center;
  font-family: "AnebaNeue";
  font-weight: 600;
  height: 64px;
  width: 192px;
  box-sizing: border-box;
  border-radius: 8px;
  background: radial-gradient(100% 100% at 50% 0%, #ffd951 6.77%, #cb4848 99.48%);
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  color: #570f0b;
}

.create-cannel-btn-christmas {
  background: linear-gradient(90deg, #b80000, #8b0000) !important;
  color: #fff !important;
}

.create-box-btn .next-btn {
  font-size: 24px;
  line-height: 138.8%;
  text-align: center;
  font-family: "AnebaNeue";
  font-weight: 600;
  height: 64px;
  width: 192px;
  box-sizing: border-box;
  background: url("./HomeAssets/assets/images/dark-btn-bg.png");
  background-size: cover;
  border-radius: 8px;
  color: #fff;
  outline: none;
  border: none;
}

.form-create {
  /* width: 400px; */
  position: relative;
  height: 520px;
  width: 400px;
}

/* multistep ellipse css */
.step-box-main {
  position: relative;
}

.step-box-main .step-verticle-bar {
  height: 171px;
  width: 4px;
  background-color: #7a7e86;
}

.steps-ellipise-position {
  position: absolute;
  top: -1%;
  left: -9px;
}

.steps-ellipise-position-2 {
  position: absolute;
  top: 30%;
  left: -9px;
}

.steps-ellipise-position-3 {
  position: absolute;
  top: 60%;
  left: -9px;
}

.steps-ellipise-position-4 {
  position: absolute;
  top: 90%;
  left: -9px;
  color: #ffc120 !important;
}

.steps-ellipise-position-4-christmas {
  color: #ff3c3c !important;
}

.step-ellipise {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.step-ellipise .step-ellipise-eli {
  width: 24px;
  height: 24px;
  background: #211a2e;
  border: 4px solid #7a7e86;
  margin-right: 20px;
  /* position: relative; */
  border-radius: 50%;
}

.step-ellipise .step-ellipise-eli .step-ellipise-round {
  width: 12px;
  height: 12px;
  background: #ffd951;
  border-radius: 50%;
  margin: 2px auto;
}
.step-ellipise .step-ellipise-eli .step-ellipise-round-christmas {
  background: #ff3c3c;
}

.step-ellipise .step-ellipise-eli .step-ellipise-round-gray {
  width: 12px;
  height: 12px;
  background: #484a4e;
  border-radius: 50%;
  margin: 2px auto;
}

.step-ellipise .step-ellipise-text {
  color: #d2dedd;
}

.steps-box {
  margin: 0px 49px;
  /* padding: 15px 0px; */
  position: relative;
  width: 80%;
  margin-top: 82px;
  min-height: 300px;
}
.pass_wrap .field-wrap .set-pas-h1 {
  margin-top: 73px !important;
}
.field-h-box .password-reset-span {
  color: #cbd8e7;
}

.terms-condition p {
  font-family: "AnebaNeue";
  font-weight: 500;
  margin-bottom: 80px;
  margin-top: 16px;
  font-size: 16px;
  line-height: 166.8%;
  color: #d2dedd;
}
.terms-condition .last-step-link {
  margin-bottom: 14px;
}
.terms-condition .last-step-link-first {
  margin-top: -5px !important;
}
.terms-condition .last-step-link-sec {
  margin-top: 0px;
}
.terms-condition .last-step-link span {
  display: none;
}

.terms-condition p a {
  color: #ffd951;
}

.terms-condition-christmas p a {
  color: #ff3c3c;
}


.create-box-btn .confirm-signup-btn {
  font-size: 24px;
  line-height: 138.8%;
  text-align: center;
  font-family: "AnebaNeue";
  font-weight: 600;
  width: 400px;
  height: 64px;
  outline: none;
  border: none;
  /* font-family: 'AnebaNeue-SemiBold'; */
  font-weight: 600;
  color: #72140e;
  background: radial-gradient(77.34% 77.34% at 50% 22.66%, #efd183 0%, #ff0044 100%);
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  border-radius: 8px;
}

.create-box-password {
  margin-top: 55px;
}

.steps-mbl-w {
  display: none;
  height: 4px;
  width: 100%;
  position: unset;
  right: 0;
  top: 10%;
}

.steps-mbl-w-active {
  background-color: #5f607a;
}

.steps-mbl-w .step-horzi-hr {
  display: none;
}

.step-horzi-hr {
  position: relative;
  right: 0;
  width: 85%;
  height: 4px;
  background-color: #5f607a;
}

.step-title-mv {
  display: none;
  color: #cbd8e7;
  font-size: 16px;
  position: absolute;
  top: 30px;
  width: 93%;
  top: 30px;
  margin: 0px 3.5%;
  min-width: 150px;
}

/*  steps postions MV */
.next-step-hz-first {
  position: absolute;
  top: -9px;
  right: 92%;
}

.next-step-hz-secound {
  position: absolute;
  top: -9px;
  right: 58px;
}

.next-step-hz-third {
  position: absolute;
  top: -9px;
  right: 26px;
}

.next-step-hz-fourth {
  position: absolute;
  top: -9px;
  right: -6px;
}

.creation-last-step-img {
  position: absolute;
  top: -7px;
  left: -4px;
}

.create-user-right {
  position: relative;
  /* bottom: -24px; */
  margin-left: 27px;
  width: 400px;
  margin-top: 64px;
}

/* Error box */
.error-box {
  width: 100%;
  text-align: end;
  color: #ff0044;
  margin: 1.5px;
}

.error-box span {
  color: #ff0044;
  margin-bottom: 5px;
}

.create-user-left-inner {
  height: 100%;
  display: flex;
  align-items: center;
}

.step-mble-w-last-step {
  display: none;
  height: 4px;
  width: 82%;
  position: unset;
  /* right: 68px; */
  left: 0;
  top: 10%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.next-step-hz-first-state-four {
  position: absolute;
  top: -9px;
  right: 87.2%;
}

.next-step-hz-fourth-laststep {
  position: absolute;
  top: -12px;
  right: -56px;
}

.step-title-mv-laststep {
  color: #cbd8e7;
  font-size: 16px;
  position: absolute;
  width: 150px;
  margin: 30px 0px 0px -70px;
  text-align: center;
}

/* 768 */

.create-user-form {
  display: flex;
  flex-direction: row;
}

.create-user-form .form-left {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
}

.create-user-form .form-right {
  position: relative;
  display: flex;
  align-items: center;
  width: 60%;
  height: 100%;
}

.form-right .form {
  /* height: 100%; */
  position: relative;
  /* height: 100%; */
  /* bottom: 10%; */
}

.hide-arrows {
  -moz-appearance: textfield;
  /* Firefox */
  appearance: textfield;
}

.hide-arrows::-webkit-inner-spin-button,
.hide-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.join-platform {
  color: #ffd951 !important;
}

.join-platform-christmas {
  color: #ff3c3c !important;
}

.create-btn-wrap-mt {
  margin-top: 102px;
}

.field-h-box-h1-step-one {
  height: 40px;
}

.create-box-btn-stepOne {
  margin-top: 150px;
}

.create-box-btn-phone-mt {
  margin-top: 150px;
}

.toastImageLogo {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}

/* game wrapper */
.games_bg_wrapper {
  width: 100%;
  height: 100%;
  min-height: 100dvh;
  background-image: url("./HomeAssets/assets/images/dark-updated-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.cus-game-wrap-padding-top {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}
.cus-game-wrap-padtop-0 {
  padding-top: 0px !important;
}
.games_bg_wrapper .game_wrapper_bg_innner {
  width: 100%;
  height: 667px;
  border-radius: 16px !important;
  overflow: hidden;
  margin-bottom: 50px;
}
.games_bg_wrapper .game_wrapper_bg_innner .iframe {
  height: 100%;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
}
.iframe_game_btn_wrapper_inner_MV_inner {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.gameWrapper_loader {
  height: 667px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iframe_game_btn_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 56px;
  width: 192px;
  background-color: #2e363c;
  border-radius: 8px;
  color: #ffffff;
  font-size: 20px;
  line-height: 133.3%;
  font-family: "AnebaNeue";
  font-weight: 500;
  cursor: pointer;
  margin-right: 15px;
}
.iframe_game_btn_wrapper .img_back {
  height: 24px;
  width: 24px;
  margin-top: -5px;
  margin-left: 5px;
}
.iframe_game_btn_wrapper .img_back img {
  width: 100%;
  height: 100%;
}
.iframe_game_btn_wrapper .back_text {
  width: 128px;
}
.iframe_game_btn_wrapper_inner {
  width: 180px;
  justify-content: space-around;
  display: flex;
  align-items: center;
}

/* un autheticated lobby page */

.all_Games_wrapper {
  margin-top: 60px;
}
.all_Games_wrapper h1 {
  font-size: 36px;
  line-height: 120.6%;
  color: #bab9cb;
  font-weight: 600;
  font-family: "AnebaNeue";
}

/* jackpot css */
.jackpot_notf_bar_wrap {
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  min-height: 112px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background: linear-gradient(270deg, #1d1e20 0%, #040405 99.17%);
  box-shadow: 0px 9px 13px 5px rgba(0, 0, 0, 0.25);
}
.jackpot_notf_bar_wrap_layer {
  content: "";
  position: absolute;
  display: block;
  right: 0px;
  width: 70%;
  height: 100%;
  background-image: url("../assets/HomeAssets/assets/images/banner-layer.png");
}
/* .jackpot_notf_bar_wrap .notf_left {
  width: 40%;
  background-image: url("../assets/HomeAssets/assets/images/banner-jackpot.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
} */
.jackpot_notf_bar_wrap .notf_left img {
  height: 100%;
  width: 100%;
}
.jackpot_notf_bar_wrap .notf_right {
  width: 76%;
  padding: 16.5px 0px;
  display: flex;
  align-items: center;
  background-image: url("../assets/HomeAssets/assets/images/banner-layer.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  /* width: 100%; */
}

.notf_right .notf_mes_dflx {
  width: 96%;
  min-height: 79px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(90deg, #1f2932 0%, rgba(31, 41, 50, 0) 80%);
}
.notf_right .notf_mes_dflx_new {
  width: 96%;
  min-height: 79px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  background: linear-gradient(90deg, #1f2932 0%, rgba(31, 41, 50, 0) 80%);
}
.notf_right .notf_mes_dflx_reverse {
  flex-direction: row-reverse;
  padding: 0px 10px;
  justify-content: flex-end;
}

.notf_mes_dflx .notf_mes {
  /* padding: 0px 26px; */
  font-size: 32px;
  font-family: "AnebaNeue";
  font-weight: 600;
  /* background: linear-gradient(269deg, #687f93 15.86%, #d2dedd 99.42%); */
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.notf_mes_dflx .notf_mes_pad {
  /* padding: 0px 60px; */
}
.gray_grd_clr {
  background: linear-gradient(269deg, #687f93 15.86%, #d2dedd 99.42%);
}
.gold_grd_clr {
  background: radial-gradient(1242.14% 53.95% at 53.12% 40.63%, #ffd951 0%, #da6320 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.jackpot_coins {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.jackpot_coins span {
  color: #737f86;
  font-family: "AnebaNeue";
  font-size: 20px;
  font-weight: 600;
  margin: 0px 0px -10px 16px;
}
.jackpot_coins_span {
  margin: 0px 0px -10px 64px !important;
}
.jackpot_coins .jac_span_cj {
  font-size: 18px;
  margin: 0px 0px -10px 10px;
}
.jackpot_coins .coins_dflex {
  display: flex;
  max-width: 212px;
  justify-content: space-between;
  align-items: center;
}
.jackpot_coins .coins_dflex img {
  width: 40px;
  height: 42px;
  margin-right: 7px;
}
.jackpot_coins .coins_dflex p {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 190px;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 7px 4px rgba(0, 0, 0, 0.25);
  font-family: "AnebaNeue";
  font-size: 36px;
  font-weight: 600;
  margin: 0px;
}
.jackpot_coins .coins_dflex .cp_jac_gw {
  font-size: 30px;
}
.mydfdf {
  display: flex;
  align-items: center;
  padding: 2px;
  margin-right: 10px;
}
.jack_text_cur {
  color: #737f86;
  font-family: "AnebaNeue";
  font-size: 20px;
  font-weight: 600;
  margin: 0px 0px 2px 16px;
}
.mydfdf img {
  width: 25px;
  height: 25px;
  margin-right: 7px;
}
.mydfdf p {
  font-size: 30px !important;
}
.jackpot_coins_new {
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  width: 100%;
}
/* .jackpot_coins_new span {

  color: #737f86;
  font-family: "AnebaNeue";
  font-size: 20px;
  font-weight: 600;
  margin: 0px 0px -10px 16px;
} */
.jackpot_coins_span_new {
  width: 100% !important;
  text-align: center !important;
  margin: 0px 0px -10px 64px !important;
  color: white !important;
}
.jackpot_coins_new .jac_span_cj {
  font-size: 18px;
  margin: 0px 0px -10px 10px;
}
.coins_dflex_new {
  display: flex;
  width: 75%;
  margin-left: 10px;
  align-items: center;
  justify-content: space-between;
}
.jackpot_coins_new .coins_dflex_new img {
  width: 30px;
  height: 32px;
  margin-right: 7px;
}
.jackpot_coins_new .coins_dflex_new p {
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  /* min-width: 190px; */
  /* background-clip: text; */
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
  /* text-shadow: 0px 7px 4px rgba(0, 0, 0, 0.25); */
  font-family: "AnebaNeue";
  font-size: 36px;
  font-weight: 600;
  margin: 0px;
}
.jackpot_coins_new .coins_dflex_new .cp_jac_gw {
  font-size: 30px;
}
.multiple_coins_jackpot {
  display: flex;
}
.mob_jackpot_notf_bar_wrap {
  background: linear-gradient(270deg, #1d1e20 0%, #040405 99.17%);
  min-height: 88px;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.mob_jackpot_notf_bar_wrap_layer {
  position: absolute;
  height: 92.5%;
  width: 30%;
  content: "";
  background-image: url("../assets/HomeAssets/assets/images/bg-mobile-layer.png");
}
.mob_jackpot_notf_bar_wrap .mob_jackpot_notf_left {
  width: 55%;
  /* height: 100%; */
  background-image: url("../assets/HomeAssets/assets/images/banner-jackpot.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
}
.mob_jackpot_notf_left h5 {
  font-family: "AnebaNeue";
  font-size: 24px;
  font-weight: 600;

  margin: 0px;
  padding: 0px 15px;
  z-index: 9;
}
.mob_jackpot_notf_left .silCol {
  background: linear-gradient(269deg, #687f93 15.86%, #d2dedd 99.42%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mob_jackpot_notf_left .goldCol {
  background: radial-gradient(1242.14% 53.95% at 53.12% 40.63%, #ffd951 0%, #da6320 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*============================> New CSS for Jackpot Mobile View <============================= */
.mob_jackpot_notf_bar_wrap .mob_jackpot_notf_left_new {
  width: 30%;
  /* height: 100%; */
  background-image: url("../assets/HomeAssets/assets/images/banner-jackpot.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
}
.mob_jackpot_notf_left_new h5 {
  font-family: "AnebaNeue";
  font-size: 24px;
  font-weight: 600;

  margin: 0px;
  padding: 0px 15px;
  z-index: 9;
}
.mob_jackpot_notf_left_new .silCol {
  background: linear-gradient(269deg, #687f93 15.86%, #d2dedd 99.42%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mob_jackpot_notf_left_new .goldCol {
  background: radial-gradient(1242.14% 53.95% at 53.12% 40.63%, #ffd951 0%, #da6320 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.coins_dflex p .goldCol {
  background: radial-gradient(1242.14% 53.95% at 53.12% 40.63%, #ffd951 0%, #da6320 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.coins_dflex p .silCol {
  background: linear-gradient(269deg, #687f93 15.86%, #d2dedd 99.42%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mob_jackpot_notf_bar_wrap .mob_jackpot_notf_left img {
  width: 100%;
  height: 100%;
}

.mob_jackpot_notf_bar_wrap .mob_jackpot_notf_right_new {
  width: 65%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
}
.mob_jackpot_notf_right_new .jackpot_coins_mv_new {
  width: 100%;
  margin: auto;
  display: flex;
  /* justify-content: space-between;
  align-items: flex-end; */
  z-index: 9;
}
.mob_jackpot_notf_right_new .jackpot_coins_mv_new img {
  width: 34px;
  height: 35px;
}
.jackpot_coins_mv_new .coins_dflex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* flex-direction: column; */
}
.jackpot_coins_mv_new .coins_dflex img {
  width: 34px;
  height: 35px;
}
.jackpot_coins_mv_new .coins_dflex span {
  color: #737f86;
  font-family: "AnebaNeue";
  font-size: 16px;
  font-weight: 600;
}
.jackpot_coins_mv_new .coins_dflex p {
  font-size: 24px;
  margin-bottom: 0px;
  background: radial-gradient(77.68% 77.68% at 50% 22.32%, #ffcc4a 0%, #da6320 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "AnebaNeue";
  font-weight: 600;
  text-overflow: ellipsis;
}

.mydfdf_mv {
  display: flex;
  align-items: center;
}
.mv_coins_wrap_new {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  /* align-items: center */
}
.cur_blc_mv_new {
  color: #737f86;
  font-family: "AnebaNeue";
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

/* ==================>New CSS End Here for Jackpot Mobile View<=========================== */

.coins_dflex p .goldCol {
  background: radial-gradient(1242.14% 53.95% at 53.12% 40.63%, #ffd951 0%, #da6320 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.coins_dflex p .silCol {
  background: linear-gradient(269deg, #687f93 15.86%, #d2dedd 99.42%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mob_jackpot_notf_bar_wrap .mob_jackpot_notf_left img {
  width: 100%;
  height: 100%;
}

.mob_jackpot_notf_bar_wrap .mob_jackpot_notf_right {
  width: 45%;
  /* height: 100%; */
  display: flex;
}
.mob_jackpot_notf_right .jackpot_coins_mv {
  width: 82%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 9;
}
.mob_jackpot_notf_right .jackpot_coins_mv img {
  width: 34px;
  height: 35px;
}
.jackpot_coins_mv .coins_dflex {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.jackpot_coins_mv .coins_dflex img {
  width: 34px;
  height: 35px;
}
.jackpot_coins_mv .coins_dflex span {
  color: #737f86;
  font-family: "AnebaNeue";
  font-size: 16px;
  font-weight: 600;
}
.jackpot_coins_mv .coins_dflex p {
  font-size: 24px;
  margin-bottom: 0px;
  background: radial-gradient(77.68% 77.68% at 50% 22.32%, #ffcc4a 0%, #da6320 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "AnebaNeue";
  font-weight: 600;
  text-overflow: ellipsis;
}
.proces_bar_container {
  width: 100%;
  height: 8px;
  display: flex;
  justify-content: flex-end;
  background: linear-gradient(270deg, #36424a 47.96%, #1f2932 100%);
}
.proces_bar_container .process_bar_inner {
  position: relative;
  z-index: 1;
  float: right;
}
.timing_box_wrapper {
  /* width: 7%; */
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 40px; */
  border-radius: 50%;
  /* border-radius: 8px; */
  /* border: 2px solid #36424a; */
  background: #000;
  color: white;
  /* position: absolute;
  left: 0px;
  top: -15px; */
  text-align: center;
  margin-top: -2.1%;
  z-index: 10;
}
.timing_box_wrapper_gw {
  width: 8%;
}

.timing_box_wrapper_MV {
  /* width: 18%; */
  width: 50px;
  height: 50px;
  margin-top: -5%;
}
.timing_box_wrapper_MV span {
  font-size: 16px;
}
.timing_box_wrapper span {
  color: #fff;
  text-align: center;
  font-family: "AnebaNeue";
  /* font-weight: 600; */
  font-weight: 500;
  font-size: 19px;
}
.timing_box_wrapper .s_timbox {
  font-size: 18px;
}
.mob_jackpot_wrapper {
  display: none !important;
  width: 100%;
}
.mob_jackpot_wrapper-gamePage {
  display: none !important;
  width: 100%;
}
.jackpot-banner_abs {
  position: relative;
  /* top: 143px; */
  left: 0 !important;
  right: 0 !important;
  z-index: 98 !important;
  /* padding: 0px 19px !important; */
}
.jackpot_notf_wrapper {
  display: flex;
  flex-direction: column;
  z-index: 999;
  /* position: absolute;
  left: 0;
  right: 0; */
}
.jackpot_notf_wrapper_padd {
  width: 100%;
  padding-top: 48px;
  padding-bottom: 19px;
}
.jackpot_notf_bar_wrap .notf_right_gw {
  width: 100%;
}
.notf_right_gw .notf_mes_dflx .notf_mes_gw {
  font-size: 28px;
}

/* ============================ media queries =====ssssssssssssssssssssssss====================== */
@media (max-width: 1200px) {
  .jackpot_notf_bar_wrap .notf_right {
    width: 100%;
  }
  .jackpot_coins .coins_dflex p {
    font-size: 30px;
    min-width: 126px;
  }
  .jackpot_coins span {
    font-size: 18px;
    margin: 0px 0px -10px 47px;
  }
  .notf_mes_dflx .notf_mes {
    font-size: 24px;
    /* padding: 0px 20px; */
  }
}
@media (max-width: 1124px) {
  .timing_box_wrapper span {
    font-size: 16px;
  }
}

@media (max-width: 998px) {
  /* .jackpot_notf_wrapper {
    display: none;
  } */
}
@media (min-width: 768px) {
  /* .jackpot_notf_wrapper {
    display: none;
  }
  .jackpot_notf_wrapper_padd {
    display: none;
  } */
  .final-step-mb-15 {
    margin-bottom: 15px;
  }
  .fb-btn-box {
    /* display: flex !important; */
    /* justify-content: center; */
    align-items: center;
    flex-direction: column !important;
    /* color: white !important; */
  }
}

@media (max-width: 768px) {
  .jackpot_notf_wrapper {
    display: none;
  }
  .jackpot_notf_wrapper_padd {
    display: none;
  }
  .mob_jackpot_wrapper {
    display: block !important;
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999 !important;
  }
  /* .mob_jackpot_wrapper {
    display: relative !important;
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999 !important;
  }
  .mob_jackpot_wrapper {
    display: block !important;
  } */
  .mob_jackpot_wrapper-gamePage {
    display: block !important;
    position: relative !important;
    /* top: 0;
    left: 0;
    right: 0; */
    /* z-index: 9999; */
  }
  /* header of signup */
  .signup_header_conatiner {
    display: none;
  }
  .theme-container-mob-pad {
    padding: 0px 15px;
  }
  /* create user form  */
  .create-user-inner-right {
    display: block;
  }

  .create-user-right {
    margin-left: 0px;
  }

  .create-user .create-user-form {
    width: 95%;
  }

  .steps-mbl-w .step-horzi-hr {
    display: block;
    position: relative;
    float: right;
    border-radius: 10px;
  }

  .form-create {
    margin-top: 45px;
  }

  .field-h-box h1 {
    font-size: 36px;
  }

  .create-box-btn .next-btn {
    width: 192px;
  }

  .create-box-btn .cannel-btn {
    width: 192px;
  }

  .create-box-btn .confirm-signup-btn {
    margin-bottom: 15%;
  }

  .basic-info {
    margin-left: 5%;
  }
  /* game wrapper */
  .games_bg_wrapper .game_wrapper_bg_innner {
    width: 90%;
    height: 70dvh;
    margin: auto;
  }

  .iframe_game_btn_wrapper {
    margin-top: 15px;
  }
}

@media (max-width: 599px) {
  .mt0-mv {
    margin-top: 0px !important;
  }
  /* font sizes chnages  */
  .next-step-hz-first-move {
    right: 91% !important;
  }
  .next-step-hz-first-move-basic {
    right: 91.5% !important;
  }
  .f-36-semibold {
    font-size: 32px !important;
  }
  .input-box .input-box-field input {
    font-size: 14px !important;
  }
  .input-box .input-box-field label {
    font-size: 10px !important;
  }
  .f-14-font {
    font-size: 12px !important;
  }
  .create-box-btn .create-btns {
    font-size: 20px;
  }
  .terms-condition p {
    font-size: 14px !important;
    margin-bottom: 0px;
  }
  .f-16-font-medium {
    font-size: 14px !important;
  }
  .field-h-box .set-pass-h1 {
    margin-top: 0px;
  }
  .btn-wrap-mb-64 {
    margin-bottom: 0px;
  }

  /*  wrapper create user */
  .wrapper-create-user {
    position: relative;
    min-height: 750px;
  }
  .wrapper-create-user .create-user {
    background-position: center;
    min-height: 750px;
  }
  .step-title-mv {
    display: block;
    font-size: 14px !important;
  }

  .create-user-form {
    height: 100vh !important;
    min-height: 750px !important;
    background-color: #5f607a !important;
    width: 100% !important;
    background: none !important;
    box-shadow: none !important;
    border: none !important;
    /* display: flex;
        flex-direction: column;
        justify-content: space-evenly; */
    display: flex;
    flex-direction: column;
  }

  .step-ellipise .step-ellipise-eli {
    border: 4px solid #5f607a;
  }
  .step-ellipise .step-ellipise-eli .step-ellipise-round-gray {
    background: #5f607a;
  }

  .final-step-mb-15 {
    margin-bottom: none;
  }

  .steps-mbl-w {
    display: block;
    width: 100%;
    max-width: 100%;
  }

  .step-mble-w-last-step {
    display: block;
    width: 85%;
  }

  .create-user-form .form-left {
    width: 100%;
    display: flex;
    height: 35%;
    min-height: 200px;
  }

  .create-user-form .form-right {
    width: 93%;
    margin: 0px auto;
    position: relative;
    height: 65%;
    min-height: 550px;
  }

  .form-right .form {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: unset;
  }

  .create-user-right {
    position: unset;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    bottom: none;
  }

  /* .create-user-form {
    height: 100vh !important;
    min-height: 800px;
    background-color: #5f607a !important;
    width: 100% !important;
    background: none !important;
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
  } */

  .create-user-right-finalstep {
    height: 89%;
    margin-bottom: 14%;
  }
  .terms-condition .last-step-link {
    display: flex;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
  .terms-condition .last-step-link span {
    display: block;
    padding-right: 3px;
  }

  .steps-box {
    display: none;
  }

  .steps-box {
    margin: 0px 10%;
    padding: 15px 0px;
    position: relative;
    width: 80%;
    min-height: 300px;
  }

  .step-box-main {
    position: relative;
  }

  .steps-box-mv-process {
    width: 100%;
    position: relative;
  }

  .input-box {
    width: 100%;
  }

  .pass_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .create-box-btn {
    width: 100%;
  }

  .create-box-btn .next-btn {
    width: 192px;
  }

  .create-box-btn .cannel-btn {
    width: 192px;
  }

  .create-box-btn {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
  }

  /* game wrapper */
  .gameWrapper_loader {
    height: 100dvh;
  }
  .iframe_game_btn_wrapper {
    display: block;
  }
  .games_bg_wrapper .game_wrapper_bg_innner {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 75dvh;
    border-radius: 0px;
  }
  .iframe_game_btn_wrapper_inner_MV {
    position: absolute;
    bottom: 0;
    height: 12dvh;
    width: 100%;
    display: flex;
    background-color: black;
    left: 0;
    align-items: center;
    justify-content: center;
  }
  .iframe_game_btn_wrapper {
    display: flex;
    margin: auto;
  }
  .games_bg_wrapper {
    background-image: none;
  }
  .iframe_game_btn_wrapper_inner_MV_inner {
    width: 85%;
    border-radius: 8px;
    background-color: #2e363c;
  }

  .mob_jackpot_notf_bar_wrap {
    display: flex;
  }
  .mob_jackpot_wrapper-gamePage {
    display: block !important;
    position: relative !important;
    /* top: 0;
    left: 0;
    right: 0; */
    /* z-index: 9999; */
  }

  .mob_jackpot_wrapper {
    display: block !important;
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999 !important;
  }
  .mob_jackpot_wrapper {
    display: block !important;
  }
  .fb-btn-box {
    /* display: flex !important; */
    /* justify-content: center; */
    align-items: center !important;
    flex-direction: column !important;
    /* color: white !important; */
  }
}

@media (max-width: 460px) {
  .step-title-mv {
    margin: 0px 4.5%;
  }
}

@media (max-width: 430px) {
  .step-title-mv {
    margin: 0px 3.5%;
  }

  .create-box-btn {
    width: 100%;
  }

  .create-box-btn .next-btn {
    width: 187px;
  }

  .create-box-btn .cannel-btn {
    width: 187px;
  }

  .basic-info {
    margin-left: 4.5%;
  }

  .next-step-hz-first-state-four {
    right: 86.2%;
  }
}

@media (max-width: 390px) {
  .create-box-btn {
    width: 100%;
  }
  .next-step-hz-first-move-basic {
    right: 92.5% !important;
  }
  .create-box-btn .next-btn {
    width: 170px;
  }

  .step-horzi-hr {
    width: 83%;
  }

  .create-box-btn .cannel-btn {
    width: 170px;
  }

  .basic-info {
    margin-left: 4.5%;
  }

  .step-mble-w-last-step {
    width: 83%;
  }

  .next-step-hz-first-state-four {
    right: 85.2%;
  }
}

@media (max-width: 375px) {
  .create-box-btn {
    width: 100%;
  }

  .create-box-btn .next-btn {
    width: 170px;
  }

  .create-box-btn .cannel-btn {
    width: 170px;
  }
  .step-horzi-hr {
    width: 82%;
  }
  .basic-info {
    margin-left: 4.5%;
  }
}

@media (max-width: 360px) {
  .create-box-btn {
    width: 100%;
  }

  .create-box-btn .next-btn {
    width: 160px;
  }

  .create-box-btn .cannel-btn {
    width: 160px;
  }

  .next-step-hz-first-state-four {
    right: 81%;
  }
}

.fb-btns {
  background-color: #395693 !important;
  border: none;
  color: white !important;
  /* padding: 10px 20px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* font-size: 16px; */
  border-radius: 5px;
  cursor: pointer;

  width: 288px;
  height: 64px;
  font-size: 24px;
  line-height: 140.8%;
  font-weight: 600 !important;
  /* color: #000000 !important; */
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
  /* box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32); */
  /* border: none;
  border-radius: 8px;
  font-family: "Aneba Neue";
  font-size: 16px;
  line-height: 161.8%;
  text-align: center; */
  /* color: #570f0b !important; */
  /* padding: 8px 16px; */
  /* display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-style: normal;   */
}

.fb-btn-img {
  height: 25px !important;
  width: 25px !important;
  margin-right: 10px !important;
}

.fb-btn-box {
  display: flex !important;
  justify-content: center;
  align-items: space-between;
  flex-direction: row !important;
  color: white !important;
}

.input-container {
  width: 100% !important;
  position: relative;
}
.label {
  position: absolute;
  left: 10px;
  top: 14px;
  transition: all 0.2s;
  padding: 0 2px;
  z-index: 1;
  color: #ffffff;
}
.text-input {
  background: #030303;
  border: 2px solid #484a4e;
  box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  height: 64px !important;
  font-family: "AnebaNeue";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px !important;
  color: #ffffff;
  padding: 0 4px;
  width: 100%;
  /* width: 100%;
  height: 100%;
  padding: 0.8rem;

  border: none;
  background: #040405;

  border-radius: 5px;
  font-family: "AnebaNeue";
  font-size: 16px;
  outline: none;
  transition: all 0.3s;
  color: #fff; */
}
.label::before {
  content: "";
  height: 5px;
  background-color: #040405;
  position: absolute;
  left: 0;
  top: 10px;
  width: 100%;
  z-index: -1;
}

.text-input:focus {
  /* border: 2px solid #ffffff; */
}

.text-input:focus + .label {
  top: -10px;
  font-size: 14px;
  color: #ffffff;
}

.text-input::placeholder {
  font-size: 16px;
  opacity: 0;
  transition: all 0.3s;
}

.text-input:focus::placeholder {
  opacity: 1;
  animation-delay: 0.2s;
}

.css-13cymwt-control {
  background: transparent !important;
  border: none !important;
}

.state__input-container .css-qbdosj-Input {
  color: white !important;
}

.state__control--is-focused {
  background: transparent !important;
  border: none !important;
}

#react-select-3-input {
  color: white !important;
}

.satellite_inner {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  min-height: 100dvh;
}
.theme-container-padd-100 {
  padding-top: 50px !important;
}

.verification_div_wrapp {
  text-align: center;
  margin: 0px auto;
  width: 528px;
  height: 603px;
  border-radius: 16px;
  opacity: 0.9;
  background: linear-gradient(
    107deg,
    rgba(165, 173, 176, 0.5) 0%,
    rgba(98, 105, 123, 0.5) 51.56%,
    rgba(15, 16, 31, 0.5) 100%
  );
  box-shadow: 0px 11px 19px -1px rgba(0, 0, 0, 0.24);
}
.verify_padd_tb_50 {
  padding: 100px 0px 50px 0px !important;
}
.verification_div_wrapp .identity_logo {
  width: 72px;
  margin: 0px auto;
  padding: 33px 0px 28px 0px;
}
.verification_div_wrapp .identity_logo img {
  height: 100%;
  width: 100%;
}
.verification_div_wrapp .identity_title {
  color: #fff;
  text-align: center;
  font-family: "AnebaNeue";
  font-weight: 600;
  font-size: 36px;
  font-style: normal;
  line-height: 120.6%; /* 43.416px */
  margin-bottom: 28px;
}
.verification_div_wrapp .identity_para {
  width: 76%;
  margin: 0px auto;
  color: #cbd8e7;
  font-family: "AnebaNeue";
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 156.8%; /* 25.088px */
}
.verification_div_wrapp .identity_para .iden_para_mb {
  margin-bottom: 28px !important;
}
.identity_btns {
  display: flex;
  justify-content: space-between;
  width: 76%;
  margin: 69px auto 0px;
}
.identity_btns .identity_btns_btn {
  color: #fff;
  text-align: center;
  font-family: "AnebaNeue";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 138.8%; /* 33.312px */
  width: 48%;
  height: 64px;
  border-radius: 8px;
}
.identity_btns .identity_btns_back {
  background: radial-gradient(
    70.31% 70.31% at 50% 22.66%,
    #b6acbb 0%,
    #4c444e 41.67%,
    #252231 100%
  );
  box-shadow:
    0px 15px 13px 1px rgba(0, 0, 0, 0.32) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.identity_btns .identity_btns_proceed {
  color: #011343 ;

  background: radial-gradient(100% 100% at 50% 0%, #ffd951 6.77%, #cb4848 99.48%);
  box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.32);
}
/* jumio button sttaus  */
.jumio_status {
  display: flex;
  width: 96px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  height: 40px;
  border-radius: 8px;
  background: #01a4b7;
  color: #011343;
  text-align: center;
  font-family: "AnebaNeue";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 168.8%; /* 27.008px */
}

.cashout-card-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
}
/* .cashout-card-img img{
  height: auto !important;
  width: 100px !important;
} */
/* .cashout-card-img-all img{
  height: auto !important;
  width: 200px !important;
} */

/* .cashout-lobby-section{
  height: 100vh !important;
} */
@media (max-width: 575px) {
  /* .cashout-card-img-all img{
    height: 176 !important;
    width: 176px !important;
  }
  .cashout-card-text{
    width: 176px !important;
  } */
}
/* notification badge */
.badge {
  font-size: 0.75em; /* Adjust size as needed */
  padding: 0.5em 0.6em;
  border-radius: 800%;
  color: #72140e !important;
  background: #e9a14b !important;
}
#tokenExCardNumber, #tokenExCardCvv{
  max-height: 70px;
}