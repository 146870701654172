@media screen and (max-width: 2560px) {
}

@media screen and (max-width: 1025px) {
  .login-form {
    width: 43%;
  }

  .back img {
    width: 2.5vw;
    height: 2.5vw;
    margin-top: 0.5%;
  }

  .showeye {
    width: 3vw;
  }

  .login-logo {
    width: 72px;
    margin: auto;
  }

  .w27 {
    width: 34% !important;
  }

  .creation-header-img {
    width: 8%;
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .lobby-banner-winning {
    padding: 70px 0px;
  }
  .LobbyBanner-wrap {
    margin-top: 70px;
  }
  .lobby-banner-winning h1 {
    margin-top: 40px;
  }
  .lobby-banner-winning {
    position: static !important;
  }
  .lobby-banner-winning h1 {
    font-size: 32px !important;
  }
  .lobby-banner-winning p {
    font-size: 20px !important;
    width: 100% !important;
  }
  .LobbyBanner {
    display: none;
  }
  .mob_jackpot_notf_right .jackpot_coins_mv {
    justify-content: flex-end;
  }
  .coin_img {
    margin-right: 10px;
  }
  .wrap-login-body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    max-height: 180vh;
  }

  .login-form {
    width: 56%;
  }

  .showeye {
    width: 3vw;
  }

  .back img {
    width: 3vw;
    height: 3vw;
    margin-top: 1%;
  }

  .creation-header {
    display: none;
  }

  .mt2 {
    margin-top: 0%;
  }

  .mt6 {
    margin-top: 0%;
  }
  .jumio-iframe {
    margin-top: 50px;
    margin-bottom: 110px;
  }
  .our-location-wrap{
    margin-top: 30px;
  }
  .location-title{
    margin-top: 300px;
  }
}

@media screen and (max-width: 520px) {
  .confetti-lottie-container {
   
    width: 487px !important}
    .newAnimation{
      width: 487px !important;
      height: 487px;
    }
  .container-2-agree {
    padding-left: 10% !important;
    text-align: left;
}
  .podium-item{
    width: 211px;
    padding: 7px;
  }
  .icon-referral {
    width: 58%;
    margin: auto;
    margin-top: 6px;
    margin-bottom: 9px;
}
.icon-referral img {
  width: 100%;
border-radius: 50%;
height: 58px;
object-fit: cover;
}
.label-referral {
  font-size: 13px;
  margin-bottom: 6px;
}
.title-refeeral-place {
  font-size: 23px;}
  .main-content {

    height: 67px;}
    .content {
  
      height: 81px;

      clip-path: polygon(9% 0, 90% 0, 100% 21%, 100% 100%, 0 100%, 0 19%);
  }

  .content-main {

    height: 64px;
    
    clip-path: polygon(90% 0, 100% 34%, 100% 100%, 0 100%, 0 0);
}
  .should-hide-des{
    display: block ;
  }
  .provider-style{
    width: 50% !important;

  }
  .res-star{
    padding: .5rem 2px .5rem 5px !important;
    width: 54%;
  }
  .leaderboard-table td {

    font-size: 13px;
}
  .font-size-28{
    font-size: 18px !important;
  }
  .mb-win{
    width: 173px;
    padding: .5rem 0px .5rem 1px !important;
  }
  .star-num svg {
    width: 33px !important;
    height: 33px !important;
}
  .star-num h1 {
    position: absolute;
    top: 8px;
    left: 11.5px;
    font-size: 15px;
    font-weight: 600;
}
.star-num p {
  margin-bottom: 0%;
  margin-left: 9px;
  font-size: 15px;
  font-weight: 600;
}
  .star-detail {
    margin-left: -4px;
}
.star-img {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  margin-right: 5px;
}
.star-num {
  position: relative;
  width: 43px;
}
.star-detail svg{
  width: 30px;
  height: 30px;
  margin-right: 5px !important;
}
.test-gradeient {
  font-size: 37px;}
  .scrollable-tbody {
    display: block;
    max-height: 285px;
    overflow-y: auto;
    scrollbar-width: none;
}
  .leaderboard-table tr{
    justify-content: space-between;
  }
  .spinWheel-container{
    flex-direction: column-reverse;
  }
  .spinWheel-container-wrap {
    position: absolute;
    transform: scale(0.87);
    margin: auto;
    rotate: -45deg;
    left: 1px;
    top: 408px;
}
.bhdLno {

  width: 398px !important;
  
  height: 398px !important;
  
}
.spinWheel-wrap-logo {
  cursor: pointer;
  rotate: 45deg;
  width: 181px;
  height: 173px;
  position: absolute;
  top: 242.5px;
  left: 110.5px;
  z-index: 2000;
  transform: translate(-50%, -50%);
}
  .spin-wheel-btn2{
    display:  none !important;
  }
  .spin-wheel-btn{
    display: block !important;
  }
  .wheel-offcanvas{
    width: 100% !important;
  }
  .spinWheel-container-img {
    width: 405px;
    height: 453px;
    margin: auto;
}
.wheel-des {
  width: 90%;
  margin-top: 50%;
  height: 208px;

}
  .noft-img {
    height: auto !important;
  }
  .xcd-pro {
    font-size: 20px;
  }
  .banner-content {
    margin-top: 17%;
  }
  .img-gift {
    width: 48%;
    height: auto;
    margin-bottom: 20px;
  }
  .terms-link-mvh {
    display: none;
  }
  .new-err {
    margin-top: 2%;
    font-size: 16px;
  }
  .fb-wrap {
    padding-top: 4.5% !important;
  }
  .signin-fb {
    margin-top: 43px !important;
  }
  .signin_fb {
    margin-bottom: 3%;
  }
  /* .lobby-banner-winning {
    position: static !important;
  }
  .lobby-banner-winning h1 {
    font-size: 32px !important;
  }
  .lobby-banner-winning p {
    font-size: 20px !important;
    width: 100% !important;
  } */
  .game-setting {
    margin-top: 0%;
    padding-top: 445px;
  }

  .des-d-none {
    display: block !important;
  }

  .support_btn.d-flex.align-items-center.mb-d-none {
    display: none !important;
  }

  .support-clr {
    color: #798790 !important;
    padding: 30px 0px 0px 0px;
  }

  .mbtop {
    margin-top: 66px !important;
  }

  .newpassword-btn {
    margin-top: 121px !important;
  }

  .reset-email {
    display: block !important;
  }

  .mt-resend {
    margin-top: 65px !important;
  }

  .mt-resend {
    margin-top: 211px !important;
  }

  .showeye {
    width: 6vw;
  }

  .signin-des {
    font-size: 16px;
    line-height: 19px;
    color: #aaaaaa;
    margin-top: 5%;
    float: left;
    font-family: "AnebaNeue";
    margin-bottom: 0%;
  }

  .dis-none {
    display: none;
  }

  .desk-none {
    display: block;
  }

  .center-side-login-img {
    height: auto;
  }

  .center-side-login {
    width: 100%;
    height: auto;
  }

  .login-form {
    background: transparent;
    opacity: 0.95;
    box-shadow: none;
    padding-top: 0%;
    padding-bottom: 0%;
    left: 0%;
    margin-top: 0;
    width: 100%;
    border-radius: 0;
  }

  .signin {
    margin-top: 107px;
  }

  .login-body {
    background-image: url("./images/mobileloginpage.png");
  }

  .wrap-login-body {
    padding-top: 22.5%;
  }

  .login-logo {
    width: 90px;
    margin: auto;
  }

  .welcome-bk {
    margin-top: 6%;
    font-size: 32px;
  }

  .login-form-control {
    opacity: 0.7;
  }

  .mt-reset {
    margin-top: 192px !important;
  }

  .back img {
    width: 4vw;
    height: 4vw;
    margin-right: 2%;
    cursor: pointer;
    margin-top: 1.5%;
  }

  .back h1 {
    font-family: "AnebaNeue";
    font-size: 16px;
    line-height: 24px;
    color: #aaaaaa;
    margin-top: 1%;
    cursor: pointer;
    margin-bottom: 5%;
  }
  .lobby-banner-winning {
    padding: 50px 0px;
  }
  .LobbyBanner-wrap {
    margin-top: 50px;
  }
  .lobby-banner-winning h1 {
    margin-top: 20px;
  }
  .verification_div_wrapp {
    width: 94%;
    background: none;
    box-shadow: none;
    border-radius: 0px;
  }
  .verification_div_wrapp .identity_logo {
    padding-top: 30px;
  }
  .verification_div_wrapp .identity_para {
    width: 100%;
  }
  .identity_btns {
    margin-top: 143px;
    width: 100%;
  }
  .entries_btn_mob{
    width: 85px !important;
    font-size: 16px !important;
    margin: 2px;
    padding: 0px !important;
  }
  .location-title{
    margin-top: 100px !important;
  }
  .text-container-popup-agree{
    width: 76vw !important;
    z-index: 10000;
  }
  .scroll-container{
    height: 400px;
    overflow: scroll;
  }
  .display-flex-i-m{
    display: flex !important;
  }
}

.kyc-container {
  background: white;
}
.jumio-iframe {
  margin-top: 50px;
}


@media screen and (max-width: 426px) {

  .jumio-iframe {
    margin-bottom: 110px;
  }
  .kyc-container {
    width: 100%;
    min-height: 100vh;
    background: white;
  }
  .signin-des {
    font-size: 16px;
    line-height: 19px;
    color: #aaaaaa;
    margin-top: 5%;
    float: left;
    font-family: "AnebaNeue";
    margin-bottom: 8%;
  }

  .mt-resend {
    margin-top: 206px !important;
  }

  .dis-none {
    display: none;
  }

  .desk-none {
    display: block;
  }

  .center-side-login-img {
    height: auto;
  }

  .center-side-login {
    width: 100%;
    height: auto;
  }

  .login-form {
    background: transparent;
    opacity: 0.95;
    box-shadow: none;
    padding-top: 0%;
    padding-bottom: 0%;
    left: 0%;
    margin-top: 0;
    width: 100%;
    border-radius: 0;
  }

  .signin {
    margin-top: 107px;
  }

  .login-body {
    background-image: url("./images/mobileloginpage.png");
  }

  .wrap-login-body {
    padding-top: 27.5%;
  }

  .login-logo {
    width: 90px;
    margin: auto;
  }

  .welcome-bk {
    margin-top: 6%;
  }

  .login-form-control {
    opacity: 0.7;
  }

  .mt-reset {
    margin-top: 192px !important;
  }

  .back img {
    width: 4vw;
    height: 4vw;
    margin-right: 2%;
    cursor: pointer;
    margin-top: 1.5%;
  }

  .back h1 {
    font-family: "AnebaNeue";
    font-size: 16px;
    line-height: 24px;
    color: #aaaaaa;
    margin-top: 1%;
    cursor: pointer;
    margin-bottom: 5%;
  }
  .entries_btn_mob{
    width: 85px !important;
    font-size: 16px !important;
    margin: 1px;
    padding: 0px !important;
  }
  .text-container-popup-agree{
    width: 76vw !important;
    z-index: 10000;
  }
  .cv{
    z-index: 10000;

  }
}

@media screen and (max-width: 376px) {
  .confetti-lottie-container {
   
    width: 400px !important}
    .newAnimation{
      width: 400px !important;
      height: 400px;
    }
  .provider-style{
    width: 100% !important;

  }
  .newpassword-btn {
    margin-top: 97px !important;
  }

  .back img {
    width: 5vw;
    height: 5vw;
    margin-right: 2%;
    cursor: pointer;
    margin-top: 1.5%;
  }

  .mt-resend {
    margin-top: 164px !important;
  }

  .back h1 {
    margin-bottom: 10%;
  }

  .wrap-login-body {
    padding-top: 39.5%;
  }

  .login-logo {
    width: 76px;
    margin: auto;
  }
  .entries_btn_mob{
    width: 75px !important;
  }

  /* .wrap-login-body {
        padding-top: 50.5%;
    } */
  .mt-reset {
    margin-top: 164px !important;
  }
  .entries_btn_mob{
    width: 75px !important;
    font-size: 14px !important;
    margin: 1px;
    padding: 0px !important;
  }
  .display-flex-i-m{
    display: flex !important;
  }
}

@media screen and (max-width: 321px) {
  .wrap-login-body {
    padding-top: 50.5%;
  }

  .login-logo {
    width: 76px;
    margin: auto;
  }

  .mt-reset {
    margin-top: 98px !important;
  }

  .container-2 {
    padding-left: 7%;
  }

  .mt-resend {
    margin-top: 168px !important;
  }
  .entries_btn_mob{
    width: 67px !important;
    font-size: 12px !important;
    margin: 1px;
    padding: 0px !important;
  }
  .display-flex-i-m{
    display: flex !important;
  }
}
