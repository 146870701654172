.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.snowfall-theme{
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none; 
  z-index: 10000000000000;
}
.referral-filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; /* Adjust the gap between buttons */
}

.referral-filter {
  padding: 10px 20px;
  background: #36424a;
  color: white;
  border: none;
  border-radius: 8px;
  color: #cbd8e7;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 600;
  /* margin-left: 14px; */
  font-size: 15px;
}

.referral-filter.active {
  background: radial-gradient(100% 100% at 50% 0%, #ffd951 6.77%, #cb5f48 99.48%) ;
  color: #570f0b !important;

}

.j-c-f-s{
  justify-content: flex-start !important;
}
.referral-filter:hover {
  background: radial-gradient(100% 100% at 50% 0%, #ffd951 6.77%, #cb5f48 99.48%) ;
  color: #570f0b !important;
  
}