.confetti-container {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none; /* Keep this to disable interactions with confetti */
  z-index: 100;

}

.confetti {
  position: absolute;
  top: -100px;
  background-size: contain;
  opacity: 1;
  animation: fall linear infinite;
  will-change: transform;
}

@keyframes fall {
  to {
    transform: translateY(120vh);
  }
}

.popup-container-win {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.confetti-lottie-container {
  position: fixed;
  top: 50%;
  left: 50%;
  height: auto !important;
  /* width: 50vw; */
  width: 550px;
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 20px;
  z-index: 1000;
  text-align: center;

}
.newAnimation{
  width: 550px;
  height: 550px;
}
.text-container-popup h1 {
  font-size: 40px;
  font-weight: 700 !important;
}

.yellow_text {
  -webkit-text-fill-color: #0000;
  background: radial-gradient(1242.14% 53.95% at 53.12% 40.63%, #ffd951 0, #da6320 100%);
  background-clip: text;
  -webkit-background-clip: text;
}

.yellow_bold {
  font-weight: bold !important;
}

.text-container-popup p {
  color: azure;
  font-size: 30px;
  font-weight: 400 !important;
}

.text-container-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  height: auto !important;
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translate(-50%, -50%);
  background: #2e363c;
  padding: 20px;
  border-radius: 20px;
  z-index: 1001; /* Ensure it is above confetti */
  text-align: center;
  opacity: 0;
  animation:
    fadeIn 1s ease-in-out forwards,
    pulse 2s infinite;
  border: 2px solid transparent;
  background-clip: padding-box;

  /* Gradient Glowing Border */
  box-shadow:
    0 0 5px #ffd951,
    0 0 10px #ffd951,
    0 0 20px #da6320,
    0 0 40px #da6320,
    0 0 80px #da6320;
  
  pointer-events: all; /* Enable interactions on the popup */
}

/* Close button styling */
.close-popup-btn {
  position: absolute;
  top: -20px; /* Place it above the popup */
  right: -20px; /* Place it to the right of the popup */
  background-color: #ff6347;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1002; /* Ensure it's clickable */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  font-size: 18px;
}

.close-popup-btn:hover {
  background-color: #ff4500; /* Darker shade on hover */
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    box-shadow:
      0 0 5px #ffd951,
      0 0 10px #ffd951,
      0 0 20px #da6320,
      0 0 40px #da6320,
      0 0 80px #da6320; /* Glowing gradient effect */
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1);
    box-shadow:
      0 0 10px #ffd951,
      0 0 20px #ffd951,
      0 0 40px #da6320,
      0 0 60px #da6320,
      0 0 100px #da6320; /* Pulsing gradient glow */
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    box-shadow:
      0 0 5px #ffd951,
      0 0 10px #ffd951,
      0 0 20px #da6320,
      0 0 40px #da6320,
      0 0 80px #da6320; /* Glowing gradient effect */
  }
}


/* .confetti-container {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 100;
}

.confetti {
  position: absolute;
  top: -100px;
  background-size: contain;
  opacity: 1;
  animation: fall linear infinite;
  will-change: transform;
}

@keyframes fall {
  to {
    transform: translateY(120vh);
  }
}

.popup-container-win {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.confetti-lottie-container {
  position: fixed;
  top: 50%;
  left: 50%;
  height: auto !important;
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 20px;
  z-index: 1000;
  text-align: center;
}

.text-container-popup h1 {
  font-size: 40px;
  font-weight: 700 !important;
}
.close-popup-msg {
  z-index: 9999;
  position: relative;
}
.yellow_text {
  -webkit-text-fill-color: #0000;
  background: radial-gradient(1242.14% 53.95% at 53.12% 40.63%, #ffd951 0, #da6320 100%);
  background-clip: text;
  -webkit-background-clip: text;
}

.yellow_bold {
  font-weight: bold !important;
}

.text-container-popup p {
  color: azure;
  font-size: 30px;
  font-weight: 400 !important;
}

.text-container-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  height: auto !important;
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translate(-50%, -50%);
  background: #2e363c;
  padding: 20px;
  border-radius: 20px;
  z-index: 1000; 
  text-align: center;
  opacity: 0;
  animation:
    fadeIn 1s ease-in-out forwards,
    pulse 2s infinite;
  border: 2px solid transparent; 
  background-clip: padding-box; 

 
  box-shadow:
    0 0 5px #ffd951,
    0 0 10px #ffd951,
    0 0 20px #da6320,
    0 0 40px #da6320,
    0 0 80px #da6320;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    box-shadow:
      0 0 5px #ffd951,
      0 0 10px #ffd951,
      0 0 20px #da6320,
      0 0 40px #da6320,
      0 0 80px #da6320; 
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1);
    box-shadow:
      0 0 10px #ffd951,
      0 0 20px #ffd951,
      0 0 40px #da6320,
      0 0 60px #da6320,
      0 0 100px #da6320; 
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    box-shadow:
      0 0 5px #ffd951,
      0 0 10px #ffd951,
      0 0 20px #da6320,
      0 0 40px #da6320,
      0 0 80px #da6320; 
  }
} */

/* Confetti Styles */
/* .confetti-gold {
  background-image: url("./images/coins/Gold.png");
  width: 80px;
  height: 80px;
}

.confetti-silver {
  background-image: url("./images/coins/Silver.png");
  width: 80px;
  height: 80px;
}

.confetti-bronze {
  background-image: url("./images/coins/Bronze.png");
  width: 80px;
  height: 80px;
} */

/* Responsive Styles */
@media screen and (max-width: 1025px) {
  .text-container-popup {
    width: 50vw !important;
    height: auto !important;
  }
  .text-container-popup h1 {
    font-size: 30px !important;
  }
  .text-container-popup p {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 768px) {
  .text-container-popup {
    width: 50vw !important;
  }
  .text-container-popup h1 {
    font-size: 25px !important;
  }
  .text-container-popup p {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 520px) {
  .text-container-popup {
    width: 60vw !important;
    height: auto !important;
  }
  .text-container-popup h1 {
    font-size: 20px !important;
  }
  .text-container-popup p {
    font-size: 16px !important;
  }
  .text-container-popup-agree{
    width: 82vw !important;
    z-index: 100000000000;
  }
}

@media screen and (max-width: 320px) {
  .text-container-popup {
    width: 70vw !important;
    height: auto !important;
  }
  .text-container-popup h1 {
    font-size: 15px !important;
  }
  .text-container-popup p {
    font-size: 10px !important;
  }
}
